import { Carousel as CarouselAntd } from "antd"
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom"
import './index.css'
import { IssuedTicketCard, TextBtn } from "../../shared";

interface IProps {
    myTicketList: IMyTicket[];
}
const IssuedTicketAvailableList = ({ myTicketList }: IProps) => {
    const navigate = useNavigate();
    const usableDateText = (period: string) => {
        const startDate = dayjs().format("YYYY-MM-DD");
        const endDate = dayjs(period.split(' ~ ')[1].replaceAll(".", "-"));
        const dayDiff = endDate.diff(startDate, "day", true);
        return dayDiff;
    }

    return <>
        <div className="title/t1" style={{ paddingInline: "20px" }}>보유 정기권</div>

        {myTicketList.length === 0
            ? <div style={{
                backgroundColor: "var(--secondarys100)", borderRadius: "var(--radius4)",
                margin: '28px 20px 24px',
                padding: '61px 0',
                textAlign: 'center'
            }}>
                <div className="body/b2 secondary/s600">
                    보유하신 정기권이 없습니다
                </div>
                <TextBtn type='underline' textDecorationColor='' style={{ color: 'var(--secondarys800)', marginTop: 8 }} onClick={() => navigate('/ticket')} icon={false}>
                    정기권 구매하기
                </TextBtn>
            </div>
            : <div style={{ marginTop: '28px', marginBottom: '51px', paddingInline: myTicketList.length === 1 ? "20px" : "20px 0" }}>
                <CarouselAntd draggable autoplay autoplaySpeed={5000} style={{ marginBottom: '-15px' }} slidesToShow={myTicketList.length === 1 ? 1 : 1.024}>
                    {myTicketList.map(ticket =>
                        <div key={ticket.id}
                            onClick={() => navigate(`/issued-ticket/${ticket.issuedTicketId}`)}
                            style={{ cursor: 'pointer', marginRight: "-300px" }}>
                            <div style={{
                                display: "flex", justifyContent: "space-between", flexDirection: "column",
                                height: '140px', padding: 'var(--space20)', borderRadius: 'var(--radius4)', marginLeft: myTicketList.length === 1 ? "" : "12px",
                                background: `linear-gradient(135deg, ${ticket.startColor}, ${ticket.endColor})`, color: ticket.textColor
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column", alignItems: 'start', gap: 'var(--space4)' }}>
                                    <div className="sub-title/st3">{ticket.yogaCenterName === "ALL PASS" ? ticket.yogaCenterName : "파지티브호텔 요가 " + ticket.yogaCenterName}</div>
                                    <div className="body/b1">{ticket.period + " (" + usableDateText(ticket.period) + "일 남음)"}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <div className="sub-title/st4">{ticket.ticketName}</div>
                                    <div className="body/b1">/ 잔여 횟수 {ticket.remainingCnt}회</div>
                                </div>
                            </div>
                        </div>
                    )}
                </CarouselAntd>
            </div>
        }
    </>
}

export { IssuedTicketAvailableList }