import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_LECTURELISTBYCENTER = 'lectureByCenter/GET_LECTURELISTBYCENTER';
export const GET_LECTURELISTBYCENTER_SUCCESS = 'lectureByCenter/GET_LECTURELISTBYCENTER_SUCCESS';
export const GET_LECTURELISTBYCENTER_ERROR = 'lectureByCenter/GET_LECTURELISTBYCENTER_ERROR';

export const getLectureListByCenterAsync = createAsyncAction(
    GET_LECTURELISTBYCENTER,
    GET_LECTURELISTBYCENTER_SUCCESS,
    GET_LECTURELISTBYCENTER_ERROR
)<undefined, ILectureByCenterModule, AxiosError>();


