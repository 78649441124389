import { ReactComponent as BiIconSmall } from '../../assets/images/biIconSmall.svg';
import { usableDateFormat } from '../../utils';

interface IProps {
    ticket: ITicketReceipt
    children?: React.ReactNode;

}
const TicketReceipt = ({ ticket, children }: IProps) => {

    return <>
        <div style={{ height: '135px', background: `linear-gradient(to right, ${ticket.startColor}, ${ticket.endColor})`, padding: '22px 20px 0 20px', color: ticket.textColor, borderRadius: 'var(--radius4) var(--radius4) 0px 0px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="title/t2">{ticket.totalUsableCnt}회</div>
                <BiIconSmall fill={ticket.textColor} />
            </div>
            <div style={{ marginTop: '40px' }}>
                <div className="title/t4">{(ticket.price ? ticket.price.toLocaleString() : 0)}원</div>
                <div className="body/b2" style={{ marginTop: 'var(--space4)' }}>{usableDateFormat(ticket.startDate) + " - " + usableDateFormat(ticket.expireDate)}</div>
            </div>
        </div>
        <div style={{ paddingTop: '24px', background: `linear-gradient(to right, ${ticket.startColor}, ${ticket.endColor})`, position: 'relative' }}>
            <div style={{ position: 'absolute', top: 9, left: 0 }}>
                <div style={{ width: '15px', height: '15px', backgroundColor: '#FFFFFF', borderTopRightRadius: '100px' }}></div>
            </div>
            <div style={{ position: 'absolute', top: 9, right: 0 }}>
                <div style={{ width: '15px', height: '15px', backgroundColor: '#FFFFFF', borderTopLeftRadius: '100px' }}></div>
            </div>
        </div>

        <div style={{ padding: '24px var(--space16) var(--space20)', backgroundColor: '#FFF', position: 'relative', border: '1px solid var(--secondarys300)', borderRadius: '0px 0px var(--radius4) var(--radius4)' }}>
            <div style={{ position: 'absolute', top: -1, left: -1 }}>
                <div style={{ width: '16px', height: '16px', backgroundColor: '#FFFFFF', borderRight: '1px solid var(--secondarys300)', borderBottom: '1px solid var(--secondarys300)', borderBottomRightRadius: '100px' }}></div>
            </div>
            <div style={{ position: 'absolute', top: -1, right: -1 }}>
                <div style={{ width: '16px', height: '16px', backgroundColor: '#FFFFFF', borderLeft: '1px solid var(--secondarys300)', borderBottom: '1px solid var(--secondarys300)', borderBottomLeftRadius: '100px' }}></div>
            </div>
            {children}
        </div>
    </>
}
export { TicketReceipt }