import { Flex } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../reservationBadge";

interface IProps {
    dataList: Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | null
}

const LectureCardInReservation = ({ dataList }: IProps) => {
    const navigate = useNavigate();

    return <>{dataList === null
        ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
        : <>{dataList.length === 0
            ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
            : <div style={{ paddingTop: 4 }}>{dataList.map((yogaLecture, idx) => {
                return <div
                    key={idx}
                    style={{ marginTop: 20, padding: '8px 0' }}
                    onClick={() => navigate(`/lecture/${yogaLecture.id}`)}
                >
                    <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                        <div style={{ width: '25%' }}>
                            <img src={yogaLecture.profileImageUrl} alt="수업사진" width={'100%'} style={{ borderRadius: "999px" }} />
                        </div>
                        <div style={{ width: '75%' }}>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <div className="sub-title/st1">{yogaLecture.yogaProgramName && (yogaLecture.yogaCenterName !== "강남") ? yogaLecture.yogaProgramName : ""}</div>
                                    <div className="sub-title/st3" style={{ marginTop: 2 }}>{yogaLecture.yogaClassName}</div>
                                </div>
                                <div>
                                    {yogaLecture.isReservation
                                        ? <ReservationBadge status="Complete" />
                                        : dayjs().isAfter(yogaLecture.startDateTime)
                                            ? <ReservationBadge status="Close" />
                                            : (yogaLecture.maxReservationMember - yogaLecture.nowReservationCnt > 0)
                                                ? <ReservationBadge status="Available" />
                                                : <ReservationBadge status="Close" />
                                    }
                                </div>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', marginTop: 12 }}>
                                <div className="body/b1 secondary/s500">{yogaLecture.startDateTime?.slice(11, 16)} - {yogaLecture.endDateTime?.slice(11, 16)}</div>
                                <div className="body/b1 secondary/s500">{yogaLecture.teacherName} 코치 | {yogaLecture.yogaCenterName} {yogaLecture.room}</div>
                            </Flex>
                        </div>
                    </Flex>
                </div>
            })}</div>}
        </>
    }</>


    // {dataList.map((yogaLecture, idx, arr) => {
    //     return <div key={idx}
    //         style={{ display: "flex", paddingBottom: "20px", gap: "16px", borderBottom: idx === (arr?.length - 1) ? "" : "1px solid #D9D9D9", cursor: "pointer" }}
    //         onClick={() => navigate(`/lecture/${yogaLecture.id}`)}>
    //         <div style={{ width: "24%" }}>
    //             <img src={yogaLecture.profileImageUrl} alt="" width="100%" style={{ borderRadius: "50%" }} />
    //         </div>
    //         <div style={{ width: "76%", display: "flex", flexDirection: "column", gap: "6px" }}>
    //             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                 <div style={{ fontSize: "16px", fontWeight: "600" }}>
    //                     {yogaLecture.yogaProgramName && (yogaLecture.yogaCenterName !== "강남") ? yogaLecture.yogaProgramName : ""}
    //                 </div>
    //                 <div style={{ fontSize: "9px", color: "#777777", border: "0.75px solid #999999", padding: "1px 3px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    //                     {(dayjs().isAfter(yogaLecture.startDateTime) ? '지난수업' : (yogaLecture.maxReservationMember - yogaLecture.nowReservationCnt > 0) ? "예약가능" : "예약마감")}
    //                 </div>
    //             </div>
    //             <div style={{ fontSize: "16px", fontWeight: "700" }}>
    //                 {yogaLecture.yogaClassName}
    //             </div>
    //             <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2px", fontSize: "14px", color: "#777777" }}>
    //                 <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
    //                     <span>{yogaLecture.startDateTime?.slice(11, 16)} - {yogaLecture.endDateTime?.slice(11, 16)}</span>
    //                 </div>
    //                 <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
    //                     <div>
    //                         {yogaLecture.teacherName} 코치
    //                     </div>
    //                     <div style={{ backgroundColor: '#7D7D7D', width: "1px", height: '12px' }} />
    //                     <div>
    //                         {yogaLecture.yogaCenterName}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // })}
}

export { LectureCardInReservation }