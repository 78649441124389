import { Button, DatePicker, Flex, Input, Modal, Space } from "antd"
import MainHeader from "../../../layout/MainLayout/header"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react";
import { formatPhoneNumber } from "../../../utils";
import dayjs, { Dayjs } from "dayjs";
import { sendSlackUsingWebhook } from "../../../service";
import { createPrivateClassInquiry } from "../../../service";
import { TopBar } from "../../../widgets";

const { TextArea } = Input;

const PrivateClassApplyForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [date, setDate] = useState<Dayjs>(dayjs());
    const [personValue, setPersonValue] = useState<number>(1);
    const [phone, setPhone] = useState<string>('');
    const [requestText, setRequestText] = useState<string>('');
    const [isOpenResultReservationModal, setIsOpenResultReservationModal] = useState<boolean>(false);

    const handleChange = (event: any) => {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        setPhone(formattedPhoneNumber);
    }
    const DateFormatter = (date: Dayjs) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD'); // 날짜 형식 설정
        const dayName = dayjs(date).format('ddd'); // 요일 이름 가져오기
        const fullDate = `${formattedDate} (${dayName})`;

        return fullDate;
    };

    const clickReservationPrivateClassButton = () => {
        createPrivateClassInquiry({
            programName: location.state.programName,
            status: '신청중',
            requestDate: DateFormatter(date),
            personCnt: personValue.toString(),
            mobile: phone,
            requestText: requestText
        }).then((res) => {
            if (res.data) {
                sendSlackUsingWebhook(`프라이빗 클래스 요청 \n 프로그램: ${location.state.programName} \n 신청 날짜 : ${DateFormatter(date)} \n 인원 : ${personValue} \n 연락처: ${phone} \n 요청 사항: ${requestText} \n`)
                    .then((res) => res.data && setIsOpenResultReservationModal(true))
                    .catch((error) => console.error('error', error));
            }
        }).catch((error) => console.error('error', error))
    }

    return (
        <>
            <TopBar title="신청하기" rightIcon={false} />

            <div style={{ marginTop: '16px', padding: '12px 20px' }}>
                <Flex style={{ justifyContent: 'space-between', fontSize: '16px', lineHeight: '28px' }}>
                    <div style={{ color: '#555555' }}>프로그램</div>
                    <div style={{ color: 'black' }}>{location.state.programName}</div>
                </Flex>
            </div>
            <div style={{ height: '6px', backgroundColor: '#F4F4F4', marginTop: '12px' }}></div>
            <div style={{ marginTop: '24px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>날짜를 선택하세요</div>
                <DatePicker
                    onChange={(e) => e && setDate(e)}
                    defaultValue={dayjs()}
                    size="large" style={{ width: '100%', marginTop: '12px', height: '48px' }} />
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>인원을 선택하세요</div>
                <div style={{ marginTop: '12px' }}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Button
                            onClick={() => personValue > 1 && setPersonValue(personValue - 1)}
                            size="large" style={{ height: '48px', borderRight: 'none', borderColor: '#d9d9d9' }}>-</Button>
                        <Input type="number" value={personValue} size="large" style={{ height: '48px', borderRight: 'none', borderLeft: 'none', textAlign: 'center', borderColor: '#d9d9d9', backgroundColor: 'white', color: '#000000' }} disabled />
                        <Button
                            onClick={() => setPersonValue(personValue + 1)}
                            size="large" style={{ height: '48px', borderLeft: 'none', borderColor: '#d9d9d9' }}>+</Button>
                    </Space.Compact>
                </div>
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>연락처를 확인하세요</div>
                <div style={{ marginTop: '12px' }}>
                    <Input
                        value={phone}
                        onChange={handleChange}
                        placeholder="010-1234-5678" size="large" style={{ height: '48px' }} />
                </div>
                <div style={{ marginTop: '12px', color: '#7D7D7D', fontSize: '12px' }}>수업 예약 확정 연락을 받을 수 있는 번호를 입력해 주세요.</div>
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>요청사항</div>
                <div style={{ marginTop: '12px' }}>
                    <TextArea
                        value={requestText}
                        rows={4}
                        placeholder="요청사항이 있으신 경우 작성해 주세요."
                        onChange={(e) => setRequestText(e.target.value)} />
                </div>
            </div>
            <div style={{ height: '100px' }}></div>


            <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px", textAlign: 'center' }}>
                <div style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "black", color: "#ffffff", cursor: "pointer" }}>
                    <div
                        onClick={() => clickReservationPrivateClassButton()}
                        style={{ padding: "26px 0" }}>예약하기</div>
                </div>
                {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: 'black', width: '100%' }}></div>}
            </div>

            <Modal centered open={isOpenResultReservationModal} onCancel={() => setIsOpenResultReservationModal(false)} footer={[]}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 'bold', marginTop: '20px' }}>신청을 완료했습니다</div>
                    <div style={{ marginTop: '5px', fontSize: '13px' }}>내용 확인 후 24시간 내에<br />연락처로 연락드리겠습니다</div>
                </div>
                <div style={{ backgroundColor: 'black', border: '1px solid #555', borderRadius: '5px', color: 'white', padding: '15px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                    onClick={() => {
                        setIsOpenResultReservationModal(false);
                        navigate('/private/class/apply/history');
                    }}>확인</div>
            </Modal>
        </>
    )
}

export default PrivateClassApplyForm