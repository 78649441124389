import { Divider, Flex, Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";


const MyCheckInSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<>
        <div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
            <div style={{ padding: '0 24px 118px' }}>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                    <div style={{ width: '215px', display: 'inline-block' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: '33px' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div style={{ width: 100 }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ width: '100%', marginTop: '24px' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                    <Flex style={{ justifyContent: 'space-between' }} gap={12}>
                        <div style={{ width: '100%' }}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                        </div>
                    </Flex>
                </div>

                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
                <div style={{ marginTop: 2, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
        </div>
    </>)
}
export default MyCheckInSkeleton;