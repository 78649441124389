import { Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";

const AppleLoginMobileAppCallbackSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), padding: '0 24px' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
            <div style={{ width: '215px', display: 'inline-block' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
            </div>
        </div>

        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21, marginTop: 37, width: 160 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 24 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 20 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 20 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 20 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 20 }} />
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 140, marginTop: 32 }} />

    </div>)
}

export default AppleLoginMobileAppCallbackSkeleton;