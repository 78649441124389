import dayjs from "dayjs";
interface IProps {
    issuedTicket: IMobileIssuedTicketDetail;
    style?: React.CSSProperties;
}
const IssuedTicketCard = ({ issuedTicket, style }: IProps) => {
    const usableDateText = (period: string) => {
        const startDate = dayjs().format("YYYY-MM-DD");
        const endDate = dayjs(period.split(' ~ ')[1].replaceAll(".", "-"));
        const dayDiff = endDate.diff(startDate, "day", true);
        return dayDiff;
    }

    return <div key={issuedTicket.id}
        style={{
            padding: 'var(--space20)', borderRadius: 'var(--radius4)',
            background: `linear-gradient(135deg, ${issuedTicket.startColor}, ${issuedTicket.endColor})`, color: issuedTicket.textColor,
            ...style
        }}>
        <div>
            <div className="sub-title/st3">{issuedTicket.yogaCenterName === "ALL PASS" ? issuedTicket.yogaCenterName : "파지티브호텔 요가 " + issuedTicket.yogaCenterName}</div>
            <div className="body/b1" style={{ marginTop: 'var(--space4)' }}>{issuedTicket.period + " (" + usableDateText(issuedTicket.period) + "일 남음)"}</div>
        </div>
        <div style={{ display: 'flex', marginTop: '71px' }}>
            <div className="sub-title/st4">{issuedTicket.ticketName}</div>
            <div className="body/b1" style={{ margin: '3px 0 0 8px' }}>/ 잔여 횟수 {issuedTicket.remainingCnt}회</div>
        </div>
    </div>
}

export { IssuedTicketCard }