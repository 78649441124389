import { useEffect, useState } from "react";
import MainHeader from "../../layout/MainLayout/header";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { getWeekListByIsShow } from "../../service";
import { useNavigate } from "react-router-dom";
import LoungeSkeleton from "../../components/skeleton/lounge/loungeSkeleton";
import { TopBar } from "../../widgets";

const Lounge = () => {
    const [drinkStatus, setDrinkStatus] = useState<boolean>(true);
    const [loungeStatus, setLoungeStatus] = useState<boolean>(true);
    const [weekDataList, setWeekDataList] = useState<Array<IWeek>>([]);
    const [isInitLoading, setIsIntLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        getWeekListByIsShow()
            .then((res) => { setWeekDataList(res.data); if (res.data?.length < 1) { navigate("/my") } })
            .catch((error) => { console.error("error", error); navigate("/my"); })
            .finally(() => setIsIntLoading(false))
    }, [])
    return <>
        {isInitLoading ? <LoungeSkeleton /> : <>
            <TopBar title={<div onClick={() => navigate('/')}>Wellness Lounge</div>} rightIcon={false} />
            {weekDataList.length > 0 && <>
                <div>
                    <img src={weekDataList[0].imageUrl} width="100%" alt="Wellness Lounge image" />
                </div>
                <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "24px" }}>
                    <div style={{ paddingBottom: "20px", borderBottom: "1px solid #EBEBEB" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                            <div style={{ fontSize: "18px", fontWeight: "700", textAlign: "center" }}>
                                {weekDataList[0].title}
                            </div>
                            <div style={{ fontSize: "13px", textAlign: "center", padding: "0 10%", whiteSpace: "pre-wrap" }}>
                                {weekDataList[0].description}
                            </div>
                        </div>
                    </div>
                    {weekDataList[0].itemList?.length > 0 && <div style={{ paddingBottom: "20px", borderBottom: "1px solid #EBEBEB", gap: drinkStatus ? "15px" : "", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }} onClick={(() => setDrinkStatus((curr) => !curr))}>
                            <div style={{ fontSize: "18px", fontWeight: "600" }}>
                                {weekDataList[0].itemList[0]?.title}
                            </div>
                            <div>
                                {drinkStatus ? <UpOutlined /> : <DownOutlined />}
                            </div>
                        </div>
                        <div style={{ fontSize: "13px", whiteSpace: "pre-wrap" }}>
                            {drinkStatus ? weekDataList[0].itemList[0]?.description : ""}
                        </div>
                    </div>}
                    <div style={{ paddingBottom: "20px", borderBottom: "1px solid #EBEBEB", display: "flex", flexDirection: "column", gap: loungeStatus ? "15px" : "" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }} onClick={(() => setLoungeStatus((curr) => !curr))}>
                            <div style={{ fontSize: "16px", fontWeight: "600" }}>
                                라운지 이용 안내
                            </div>
                            <div>
                                {loungeStatus ? <UpOutlined /> : <DownOutlined />}
                            </div>
                        </div>
                        <div style={{ fontSize: "13px", color: "#555555", whiteSpace: "pre-wrap" }}>
                            {loungeStatus ? weekDataList[0].userGuide : ""}
                        </div>
                    </div>
                </div>
            </>}
        </>}


    </>
}
export default Lounge;