import { AxiosResponse } from 'axios';
import api from './Api'

export const getIssuesdCouponListByMemberId = (memberId: string): Promise<AxiosResponse<Array<any>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-coupon/mobile/member/${memberId}`)
}

export const beUsedIssuedCouponById = (id: string, tossPayId: string): Promise<AxiosResponse<string, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-yoga-coupon/use/${id}`, { tossPayId })
}

export const issuedCouponUse = (id: string): Promise<AxiosResponse<any, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-coupon/use`, { id })
}

export const getIssuedCouponMobileById = (id: string): Promise<AxiosResponse<any, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-coupon/mobile/${id}`)
}
