import { Flex, Skeleton } from "antd";
import { ReactComponent as ArrowDown } from "../../../../assets/images/icon/arrow-down.svg";
import './index.css'
import { useMyContext } from "../../../../entities/context";

const ReservationSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
        <div style={{ padding: '13px 24px 118px' }}>
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <Flex style={{ width: '80px', alignItems: 'center' }} gap={4}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26 }} />
                        <ArrowDown width={16} height={16} stroke={"#AEAEAE"} />
                    </Flex>
                    <div style={{ width: 90 }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26 }} />
                    </div>
                </Flex>
            </div>

            <div style={{ margin: '37px 4px 0 4px' }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                </Flex>
                <Flex style={{ justifyContent: 'space-between', marginTop: '8px' }}>
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 26, width: 32 }} />
                </Flex>
            </div>


            <div style={{ backgroundColor: '#F4F4F4', height: '6px', marginTop: '22px' }}></div>
            <div style={{ marginTop: '24px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26, width: 80 }} />
                <div style={{ marginTop: '33px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
            </div>
        </div>
    </div>)
}

export default ReservationSkeleton