import { useEffect, useState } from "react"
import { TicketSelectCard } from "../../shared";
import TicketSkeleton from "../../components/skeleton/ticket/ticketSkeleton";
import { TopBar } from "../../widgets";
import { useNavigate } from "react-router-dom";

const Ticket = () => {
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    // const [defaultTicketList, setDefaultTicketList] = useState<IMobileDefaultTicket[]>([]);

    useEffect(() => {
        // getDefaultTicketListByCompanyId(1)
        //     .then((res) => { setDefaultTicketList(res.data); })
        //     .catch((error) => { console.error('error', error); })
        //     .finally(() => { setIsInitLoading(false); });
        setIsInitLoading(false);
    }, [])

    return <>
        {isInitLoading && <TicketSkeleton />}
        <TopBar title="정기권 선택" />
        <div style={{ padding: '24px var(--space20)' }}>
            <TicketSelectCard title="ALL PASS" description={"선릉・도산・강남 스튜디오 3곳에서 모두 \n사용할 수 있는 통합권입니다."} onClick={() => { navigate('1+2+3') }} />
            <TicketSelectCard title="강남 스튜디오" description={"강남 스튜디오에서 사용할 수 있는 \n정기권 입니다."} onClick={() => { navigate('1') }} />
            <TicketSelectCard title="선릉, 도산 스튜디오" description={"선릉・도산 스튜디오에서 사용할 수 있는 \n정기권 입니다."} onClick={() => { navigate('2+3') }} />
        </div>
    </>
}
export { Ticket }