import { ReactComponent as KakaoLoginButton } from "../../assets/images/kakaoLoginButton.svg"
import { ReactComponent as NaverLoginButton } from "../../assets/images/naverLoginButton.svg"
import { checkDevAndLocal } from "../../utils"
const DevLogin = () => {
    const KAKAO_AUTH_URL = `kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI}&response_type=code&lang=ko&scope=phone_number,birthday,birthyear,gender,profile,account_email,name`;
    const NAVER_AUTH_URL = `nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URI}&state=${process.env.REACT_APP_NAVER_LOGIN_STATE}&lang=ko_KR`;
    const clickKakaoLogin = () => {
        window.location.href = "https://" + KAKAO_AUTH_URL;
    }
    const clickNaverLogin = () => {
        window.location.href = "https://" + NAVER_AUTH_URL;
    }
    return <> {(checkDevAndLocal() === 'dev' || checkDevAndLocal() === 'local') &&
        <div style={{ border: '1px solid black' }}>
            테스트 용 Web Login 버튼
            <div style={{ display: 'flex', gap: '20px' }}>
                <KakaoLoginButton style={{ cursor: "pointer" }} onClick={clickKakaoLogin} />
                <NaverLoginButton style={{ cursor: "pointer" }} onClick={clickNaverLogin} />
            </div>
        </div>}
    </>
}

export { DevLogin }