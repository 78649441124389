import { Divider, Flex, Skeleton } from "antd"
import { ReactComponent as ArrowDown } from "../../../../assets/images/icon/arrow-down.svg";
import { useMyContext } from "../../../../entities/context";

const LoungeSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<>
        <div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), padding: '0 24px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
        </div>
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 375, marginTop: 13 }} />
        <div style={{ padding: '0 24px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '200px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>

            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 14 }} />

            <Flex style={{ alignItems: 'center', marginTop: 47 }} gap={16}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20 }} />
                <ArrowDown width={16} height={16} stroke={"#AEAEAE"} />
            </Flex>

            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 18 }} />

            <Divider style={{ margin: '20px 0 0 0' }} />

            <Flex style={{ alignItems: 'center', marginTop: 26 }} gap={16}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20 }} />
                <ArrowDown width={16} height={16} stroke={"#AEAEAE"} />
            </Flex>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 18 }} />
        </div>



    </>)
}

export default LoungeSkeleton