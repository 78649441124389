import { AxiosResponse } from 'axios';
import api from './Api'

export const createReservation = (reservation: ICreateReservation) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/reservation`, reservation);
}

export const updateReservation = (reservation: IReservation): Promise<AxiosResponse<IReservation, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation`, reservation)
}

export const myReservationByToken = (): Promise<AxiosResponse<Array<IMyReservation>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation/token`);
}

export const cancelReservation = (lectureId: number, memberId: number) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation/${lectureId}/${memberId}`)
}

export const createMobileReservation = (reservation: ICreateReservation) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation`, reservation);
}
export const updateReservationOnCheckIn = (reservation: IUpdateReservationForCheckIn) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/reservation`, reservation)
}