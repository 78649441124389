import dayjs from "dayjs"
import { RequestComponent } from "../../../../shared"
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Flex } from "antd";

interface IProps {
    title: string
    ticket?: IMobileIssuedTicketGetResponse
    isReservation: boolean
    lectureStartDateTime: string
    selectedId?: number
    setSelectedId: Function
}
const MyIssuedTicketAvaiable = ({ title, ticket, isReservation, lectureStartDateTime, selectedId, setSelectedId }: IProps) => {

    const isAvaiableTicketByLectureStartDateTime = (ticketStartDateTime: string, ticketExpireDate: string) => {
        return dayjs(ticketStartDateTime).set('h', 0).set('minute', 0).isBefore(lectureStartDateTime) && dayjs(ticketExpireDate).set('h', 23).set('minute', 59).isAfter(lectureStartDateTime)
    }

    return <>
        <div>
            <div className="sub-title/st3" style={{ paddingBlock: 6 }}>{title}</div>
        </div>
        <div style={{ marginTop: 16 }}>
            {!ticket
                ? <RequestComponent text={'로그인이 필요 합니다.'} />
                : isReservation
                    ? <RequestComponent text={`이미 예약된 수업입니다.`} />
                    : ticket.mobileIssuedMyTicketList.length === 0
                        ? <RequestComponent text={`${title}이 없습니다.`} />
                        : ticket.mobileIssuedMyTicketList.map((issuedTicket: IMobileIssuedMyTicket) =>
                            <Flex
                                key={issuedTicket.id}
                                onClick={() => isAvaiableTicketByLectureStartDateTime(issuedTicket.startDate, issuedTicket.expireDate) && setSelectedId(issuedTicket.id)}
                                style={{ padding: 12, backgroundColor: 'var(--secondarys100)', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Flex gap={8}>
                                    {(selectedId === issuedTicket.id) && isAvaiableTicketByLectureStartDateTime(issuedTicket.startDate, issuedTicket.expireDate)
                                        ? <CheckCircleFilled style={{ fontSize: '20px' }} />
                                        : <CheckCircleOutlined style={{ fontSize: '20px', color: '#AFAFAF' }} />
                                    }
                                    {/* <Space size={16}> */}
                                    <div className="sub-title/st2" style={{ fontWeight: isAvaiableTicketByLectureStartDateTime(issuedTicket.startDate, issuedTicket.expireDate) ? "700" : "400" }}>{issuedTicket.ticketName}</div>
                                    <div className="body/b2">{issuedTicket.remainingCnt}회 사용 가능</div>
                                    {/* </Space> */}
                                </Flex>
                                <div className="body/b1 secondary/s700">
                                    {isAvaiableTicketByLectureStartDateTime(issuedTicket.startDate, issuedTicket.expireDate) ? dayjs(issuedTicket.expireDate).format("YYYY.MM.DD") + "까지" : "| 사용 불가 날짜 |"}
                                </div>
                            </Flex>
                        )
            }
        </div>
    </>

}

export { MyIssuedTicketAvaiable }