
import { Flex } from "antd"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getPolicyDataTransferByMemberId, updatePolicyDataTransferAgree } from "../../service";
import dayjs from "dayjs";
import { CustomToast, CusTomSwitch, TextBtn } from "../";


interface IProps {
    title: string;
    memberId: number;
    setIsInitLoading: Function;
}

const ReceiveMarketingSwitch = ({ title, memberId, setIsInitLoading }: IProps) => {
    const navigate = useNavigate();
    const [isMarketingSwitch, setIsMarketingSwitch] = useState<boolean>();
    const [switchDisableStatus, setSwitchDisableStatus] = useState<boolean>(false);
    const [marketingToast, setMarketingToast] = useState<boolean>(false);

    getPolicyDataTransferByMemberId(memberId.toString())
        .then(res => setIsMarketingSwitch(res.data.isMarketingAgreementAgree))
        .finally(() => setIsInitLoading(false))

    return (<>
        <div style={{ padding: 12 }}>
            <Flex
                className="body/b3"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ color: 'var(--secondarys800)' }}>{title}</div>
                <CusTomSwitch
                    style={{ width: '40px', height: '24px' }}
                    defaultChecked={isMarketingSwitch}
                    disabled={switchDisableStatus}
                    onChange={(e) => {
                        setSwitchDisableStatus(true);
                        setIsMarketingSwitch(e);
                        updatePolicyDataTransferAgree({ isMarketingAgreementAgree: e })
                            .then(() => {
                                setMarketingToast(true);
                                setTimeout(() => setSwitchDisableStatus(false), 1000);
                            })
                    }} />
            </Flex>
            <div className="btn/text-btn"
                style={{
                    color: 'var(--secondarys600)',
                    textDecorationColor: 'var(--secondarys600)',
                    marginTop: 8
                }}><TextBtn style={{ padding: 0 }} type="underline" icon={false} onClick={() => navigate('/marketingAgreement')}>약관보기</TextBtn></div>
        </div>
        {marketingToast &&
            <CustomToast
                setToast={setMarketingToast}
                text={`${dayjs().format("YYYY년 MM월 DD일 HH:mm")}\n마케팅 정보 수신을 ${(isMarketingSwitch ? '동의' : '철회')}하셨습니다`} />
        }
    </>)
}
export { ReceiveMarketingSwitch }