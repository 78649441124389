import { useEffect, useState } from "react";
import ReservationSkeleton from "../../components/skeleton/reservation/reservationSkeleton";
import { YogaCenterSelcetorModal } from "./ui/yogaCenterSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { ThunkDispatch } from "redux-thunk";
import { getLectureListByCenterThunk, updateLectureParamsThunk } from "../../modules/lectureByCenter";
import { LectureCardInReservation, DateSelectorCarousel, DividerThin, GnbFooter } from "../../shared";
import { isLogin } from "../../utils";
import { getUsedYogaCenterIdByMobile } from "../../service";
import { useMyContext } from "../../entities/context";

const Reservation = () => {
    const location = useLocation();
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [selectedYogaCenterId, setSelectedYogaCenterId] = useState<number | undefined>(undefined);
    const { lectureByCenterData } = useSelector((state: RootState) => state.lectureByCenter.lectureByCenterList);
    const paramId = location.search && new URLSearchParams(location.search).get("id")
    const { topHeight } = useMyContext();

    useEffect(() => {
        if (paramId) {
            return setSelectedYogaCenterId(Number(paramId))
        }
        if (lectureByCenterData && lectureByCenterData.centerId) {
            requestGetLectureListByCenterThunk(lectureByCenterData.centerId)
            return setSelectedYogaCenterId(lectureByCenterData.centerId)
        }
        if (isLogin()) {
            getUsedYogaCenterIdByMobile()
                .then((res) => setSelectedYogaCenterId(res.data.selectYogaCenter.id ?? 3))
                .catch(error => console.error("Error: ", error))
            return;
        }
        setSelectedYogaCenterId(3);
    }, [])

    useEffect(() => {
        if (!selectedYogaCenterId) return;
        setIsInitLoading(true);
        requestGetLectureListByCenterThunk(selectedYogaCenterId)
    }, [selectedYogaCenterId])

    useEffect(() => {
        if (lectureByCenterData !== null && lectureByCenterData.data !== null) {
            const img = new Image();
            img.src = lectureByCenterData.data[0].profileImageUrl
            img.onload = () => {
                setIsInitLoading(false);
            }
        }
    }, [lectureByCenterData])

    const requestGetLectureListByCenterThunk = (yogaCenterId: number) => {
        dispatch(getLectureListByCenterThunk(yogaCenterId, lectureByCenterData?.selectedDate ? lectureByCenterData?.selectedDate : dayjs().format("YYYY-MM-DD"), dayjs().add(1, "M").format("YYYY-MM-DD")));
    }

    const lectureDataBySelectedDat = () => {
        if (!lectureByCenterData || !lectureByCenterData.data) {
            return null;
        }
        const returnValue = lectureByCenterData.data?.filter((lecture) => lecture.startDateTime.slice(0, 10) === lectureByCenterData.selectedDate);
        return returnValue;
    }

    const onClickDateSelector = (date: string) => {
        selectedYogaCenterId && lectureByCenterData && dispatch(updateLectureParamsThunk(selectedYogaCenterId, date, lectureByCenterData?.data))
    }

    return (<>
        {isInitLoading && <ReservationSkeleton />}
        <div style={{ paddingTop: (topHeight + 'px') }}></div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'baseline', padding: '13px 20px' }} >
            {!isInitLoading && <YogaCenterSelcetorModal selectedYogaCenterId={selectedYogaCenterId} setSelectedYogaCenterId={setSelectedYogaCenterId} />}
            <div onClick={() => navigate(`/yoga-center/${selectedYogaCenterId}`)}>
                <div style={{ display: "flex", gap: "4px", cursor: "pointer", alignItems: 'center' }}>
                    <div className="btn/text-btn secondary/s600">스튜디오 소개</div>
                    <ArrowRight width={12} height={12} stroke={"#737373"} />
                </div>
            </div>
        </div>

        <div style={{ marginTop: 24 }}></div>
        <DateSelectorCarousel selectedDate={lectureByCenterData?.selectedDate ?? dayjs().format("YYYY-MM-DD")} onClick={onClickDateSelector} />
        <DividerThin style={{ height: '6px', marginTop: '12px' }} />

        <div className="sub-title/st3" style={{ marginTop: '24px', paddingInline: '20px' }}>
            수업 선택
            {lectureByCenterData && <>
                <LectureCardInReservation dataList={lectureDataBySelectedDat()} />
            </>}
        </div>
        <div style={{ height: '100px' }}></div>
        <GnbFooter activeButton='reservation' />
    </>)
}

export { Reservation }