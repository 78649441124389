import { ConfigProvider, Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";

interface IProps {
    onChange?: SwitchChangeEventHandler
    defaultChecked?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}
const CusTomSwitch = ({ onChange, defaultChecked, disabled, style }: IProps) => {

    return <div className="custom-swich">
        <ConfigProvider
            theme={{
                components: {
                    Switch: {
                        handleSizeSM: 16,
                        trackPadding: 4
                    },
                },
            }}
        >
            <Switch size='small' style={style} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} />
        </ConfigProvider>
    </div>
}

export { CusTomSwitch };