import { Divider, Flex, Skeleton } from "antd";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icon/arrow-right.svg"
import { useMyContext } from "../../../../entities/context";

const MyUserSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '37px' }}>
                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: 34 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>
            <div style={{ width: '100%', marginTop: '55px' }}>
                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <div style={{ marginTop: 36 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>

            <div style={{ height: 6, backgroundColor: '#F4F4F4', margin: '36px 0' }}></div>
            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
            <Divider style={{ margin: "16px 0" }} />

            <div style={{ marginTop: 12 }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={24}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <div><ArrowRight width={16} height={16} stroke="#737373" /></div>
                </Flex>
            </div>
        </div>
    </div>)
}

export default MyUserSkeleton;