import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getKakaoPaySuccessInfo } from "../../../service";
import { getUrl, removeUrl } from "../../../service";

declare module "react-router-dom" {
    interface URLSearchParams {
        get(name: MyQueryParams): string | null;
    }
}

const KakaoPaySuccess = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    useEffect(() => {
        requestKakaoPaySuccessInfo();
    }, []);

    const requestKakaoPaySuccessInfo = () => {
        const pg_token = searchParams.get('pg_token');
        if (pg_token === null) return;

        getKakaoPaySuccessInfo(pg_token)
            .then((res: any) => {
                const beforeUrl = getUrl();
                removeUrl();
                navigate(beforeUrl ? beforeUrl : '/', { replace: true });
            })
            .catch((error) => console.error('error', error));
    }
    return <>KakaoPaySuccess</>
}
export default KakaoPaySuccess;