import { CourseBadge } from "../../"


interface IProps {
    checkIn: IMyReservationCheckIn
    style?: React.CSSProperties
}
const TrainingHistoryCard = ({ checkIn, style }: IProps) => {
    return <div style={{ ...style }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="title/t1 base/black">{checkIn.className}</div>
            <CourseBadge status={checkIn.status} />
        </div>
        <div className="body/b1 secondary/s500" style={{ marginTop: 'var(--space12)' }}>
            <div>{checkIn.date.split('T')[0].replaceAll("-", ".")} ({checkIn.day}) {checkIn.startTime} ~ {checkIn.endTime}{" | " + (checkIn.trainingCenterName ?? '')}</div>
            <div>{checkIn.yogaCenterName} {checkIn.ticketName} 사용</div>
        </div>
    </div>
}
export { TrainingHistoryCard }