import { Button, Form, Input, Select, message } from "antd";
import MainHeader from "../../../../layout/MainLayout/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAppleUserData } from "../../../../service";
import { requestSmsAuthSend } from "../../../../service";
import { requestSendAuthCodeCheck } from "../../../../service";
import { requestSignup } from "../../../../service";
import { getUrl, removeUrl } from "../../../../service";
import { updateSocialProfile } from "../../../../service";
import countryCodeList from '../CounrtyCodeList';
import PolicyDataTransfer from "../../../../components/modal/policyDataTransfer";
import { getPolicyDataTransferByToken } from "../../../../service";
import AppleLoginMobileAppCallbackSkeleton from "../../../../components/skeleton/appleLogin/appleLoginMobileAppCallbackSkeleton";
import { TopBar } from "../../../../widgets";

const AppleLoginMobileAppCallback = () => {
    const [form] = Form.useForm();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [mobile, setMobile] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('82');
    const [authCode, setAuthCode] = useState<string>('');
    const [isAuthSuccess, setIsAuthSuccess] = useState<boolean>(false);
    const beforeUrl = getUrl();
    removeUrl();
    const [socialAccessToken, setSocialAccessToken] = useState<string>('');
    const [sub, setSub] = useState<string>('');
    const [policyModalStatus, setPolicyModalStatus] = useState<boolean>(false);

    message.config({ top: 200 })

    useEffect(() => {
        if (!code) return navigate("/login");
        getAppleUserData(code)
            .then((res) => {
                if (res.data.message === '해당 아이디로는 가입이 불가능합니다.' || res.data.message.includes("가입되어 있습니다")) {
                    alert(res.data.message);
                    navigate('/login', { replace: true })
                } else if (res.data.message === '로그인 성공했습니다.') {
                    updateSocialProfile(res.data.sub, res.data.token)
                        .then(() => {
                            getPolicyDataTransferByToken()
                                .then(res => {
                                    if (!res.data.isPersonInfoPolicyAgree || !res.data.isServiceUseTermAgree) { setPolicyModalStatus(true) } else { navigate(beforeUrl ? beforeUrl : '/', { replace: true }) }
                                })
                                .catch(error => console.error("error", error))
                                .finally(() => setIsInitLoading(false));
                        })
                        .catch((error) => console.error('error', error))
                        .finally(() => setIsInitLoading(false));
                } else if (res.data.message === '회원가입을 진행해주세요.') {
                    form.setFieldsValue({ email: res.data.email });
                    setSocialAccessToken(res.data.token);
                    setSub(res.data.sub);
                    setIsInitLoading(false);
                }
            })
            .catch((error) => {
                message.error("서버에 에러가 발생했습니다.");
                navigate('/login', { replace: true });
                console.error('error', error)
            })
            .finally(() => setIsInitLoading(false));
    }, [code])

    const onFinish = (values: any) => {
        if (!isAuthSuccess) {
            alert("모바일 인증이 필요합니다.");
            return;
        }
        values.birthday = "";
        values.birthyear = 0;
        values.gender = "";

        requestSignup({ ...values, social: 'APPLE', socialAccessToken, sub })
            .then(() => {
                setPolicyModalStatus(true)
                if (!policyModalStatus) {
                    navigate(beforeUrl ? beforeUrl : '/', { replace: true })
                }
            })
            .catch((error) => console.error('error', error));
    }
    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    const clickSendAuthMessage = () => {
        requestSmsAuthSend(countryCode, mobile)
            .then((res) => console.log('res: ', res))
            .catch((error) => console.error('error', error));
    }

    const clickSendAuthCodeCheck = () => {
        requestSendAuthCodeCheck(authCode)
            .then((res) => setIsAuthSuccess(res.data))
            .catch((error) => console.error('error', error));
    }

    const clickRegisterMember = () => {
        onFinish(form.getFieldsValue())
    }

    return <>
        {isInitLoading ? <AppleLoginMobileAppCallbackSkeleton /> : <>
            <TopBar title="회원 가입" rightIcon={false} />
            <div style={{ backgroundColor: '#F2F2F2', height: '100vh', padding: '24px 20px', }}>
                <div style={{ padding: '24px 20px', backgroundColor: 'white' }}>
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        style={{ maxWidth: '480px' }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="닉네임"
                            name="name"
                            rules={[{ required: false, message: '닉네임 필수 입력값입니다.' }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item
                            label="국가 선택"
                            name="countryCode"
                            rules={[{ required: true, message: '국가 선택은 필수 입력값입니다.' }]}
                        >
                            <Select
                                value={countryCode}
                                size="large"
                                defaultValue={countryCode}
                                options={countryCodeList}
                                onChange={(value: string) => setCountryCode(value)}
                                disabled={isAuthSuccess}
                            />
                        </Form.Item>
                        <Form.Item label="전화 번호" required style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="mobile"
                                rules={[{ required: true, message: '전화 번호는 필수 입력값입니다.' }]}
                                style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                            >
                                <Input size="large" placeholder="숫자만 입력해주세요." onChange={(e) => setMobile(e.target.value)} value={mobile} defaultValue={mobile} disabled={isAuthSuccess} />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'inline-block', width: 'calc(40% - 8px)', marginLeft: '5px' }}
                            >
                                <Button onClick={clickSendAuthMessage} disabled={isAuthSuccess} size="large" >인증문자 발송</Button>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="인증번호" required style={{ marginBottom: 0 }}>
                            <Form.Item
                                rules={[{ required: true, message: '인증 번호는 필수 입력값입니다.' }]}
                                style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                            >
                                <Input size="large" onChange={(e) => setAuthCode(e.target.value)} value={authCode} defaultValue={authCode} disabled={isAuthSuccess} />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'inline-block', width: 'calc(40% - 8px)', marginLeft: '5px' }}
                            >
                                <Button size="large" onClick={clickSendAuthCodeCheck} disabled={isAuthSuccess}>인증 확인</Button>
                            </Form.Item>
                        </Form.Item>

                    </Form>
                </div>
            </div>
            {/* footer start */}
            <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px", textAlign: 'center' }}>
                <div style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "black", color: "#FFF743", cursor: "pointer" }}>
                    <div style={{ padding: "26px 0" }} onClick={() => clickRegisterMember()}>회원 등록</div>
                </div>
                {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: 'black', width: '100%' }}></div>}
            </div>
            {/* footer end */}
            <PolicyDataTransfer propsModalStatus={policyModalStatus} beforeUrl={beforeUrl} />
        </>}
    </>
}

export default AppleLoginMobileAppCallback;