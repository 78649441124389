import dayjs from "dayjs";
import { TextBadge } from "../../"
import { ReactComponent as CircleCheck } from "../../../assets/images/icon/circle-check.svg";

interface IProps {
    ticket: ITicketByYogaCenter
    isSelect?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const TicketSelectByYogaCemterCard = ({ ticket, isSelect = false, onClick }: IProps) => {
    const usableDateText = (period: string) => {
        const startDate = dayjs(period.split(' ~ ')[0].replaceAll(".", "-"));
        const endDate = dayjs(period.split(' ~ ')[1].replaceAll(".", "-"));
        const dayDiff = endDate.diff(startDate, "day", true);
        return dayDiff;
    }

    return isSelect
        ? <div style={{ background: `linear-gradient(135deg, ${ticket.startColor}, ${ticket.endColor})`, padding: 'var(--space16)', borderRadius: 'var(--radius4)', color: ticket.textColor, marginBottom: 'var(--space20)' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <div className="title/t2">{ticket.name}</div>
                    <div className="body/b2" style={{ marginTop: 'var(--space4)' }}>{ticket.period.replaceAll('~', '-') + " (" + usableDateText(ticket.period) + "일)"}</div>
                </div>
                <CircleCheck width={20} height={20} stroke={ticket.endColor} fill={ticket.textColor} />
            </div>
            <div style={{ marginTop: 'var(--space40)' }}>
                {ticket.price === ticket.discountedPrice
                    ? <div className="title/t4">{ticket.price.toLocaleString()}원</div>
                    : <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div className="title/t4">{ticket.discountedPrice.toLocaleString()}원</div>
                        <div style={{ marginLeft: '10px', textDecoration: 'line-through', fontSize: '16px' }}>{ticket.price.toLocaleString()}</div>
                    </div>}
            </div>
        </div>
        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--basewhite)', padding: 'var(--space16)', border: '1px solid var(--secondarys300)', borderRadius: 'var(--radius4)', marginBottom: 'var(--space16)', cursor: 'pointer' }} onClick={onClick}>
            <div>
                <div className="title/t2 base/black">
                    {ticket.name}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 'var(--space4)' }}>
                    <div className="body/b2 secondary/s700">{ticket.price.toLocaleString()}원 </div>
                    {(Number(ticket.discountRate) > 0) && <TextBadge text={ticket.discountRate + '%'} style={{ backgroundColor: 'var(--baseblack)', color: 'var(--basewhite)', marginLeft: 'var(--space4)' }} />}
                </div>
            </div>
            <CircleCheck width={20} height={20} stroke={"#AEAEAE"} fill={'white'} />
        </div>
}

export { TicketSelectByYogaCemterCard }