import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const KakaoPayFail = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/", { replace: true })
        console.error("KakaoPayFail")
    }, [])
    return <>KakaoPayFail</>
}
export default KakaoPayFail;