import { useEffect, useRef, useState } from "react";
import { ReactComponent as PositivehotelLogoBig } from '../../../assets/images/positivehotelLogoBig.svg'
import { ReactComponent as ArrowDown } from "../../../assets/images/icon/arrow-down.svg";

const MainPremiumMembershipContents = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isVisible, setIsVisible] = useState([true, false, false]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [logoSize, setLogoSize] = useState<[number, number]>([124, 47]);
    const [scrollTopChange, setScrollTopChange] = useState<number>(0);
    const [currentDeviceBuildVersion, setCurrentDeviceBuildVersion] = useState<boolean>(false);
    const [topHeight, setTopHeight] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }));
            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    setCurrentDeviceBuildVersion(true)
                    window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getStatusBarTopHeight', data: {} }));
                    window.getStatusBarTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true) }
                }
            }
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }))

            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getSafeAreaTopHeight', data: {} }))
                    window.getSafeAreaTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true); }
                }
            }
        }
    }, [])

    const handleScroll = (): void => {
        if (scrollContainerRef.current) {
            const scrollTop = scrollContainerRef.current.scrollTop;
            const viewportHeight = window.innerHeight;
            const maxScollTop = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight;
            setScrollTopChange(q => {
                if (q < scrollTop) {
                    if (0 < q && q < scrollTop && scrollTop !== 0 && logoSize[0] >= 72) {
                        setLogoSize(prevSizes => [(124 - scrollTop * 0.166), (46 - scrollTop * 0.166)]);
                    }
                    if (scrollTop > maxScollTop * 0.2 && scrollTop < maxScollTop * 0.4) {
                        isVisible[0] === true && setIsVisible(q => { q[0] = false; q[1] = true; return q.map(w => w) });
                    } else if (scrollTop > maxScollTop * 0.6 && scrollTop < 0.8 * maxScollTop) {
                        isVisible[1] === true && setIsVisible(q => { q[1] = false; q[2] = true; return q.map(w => w) });
                    } else if (scrollTop > 0.9 * maxScollTop) {
                        isVisible[2] === true && setIsVisible(q => { q[2] = false; return q.map(w => w) });
                    }
                } else {
                    if (q > scrollTop && q <= maxScollTop && logoSize[0] < 128) {
                        setLogoSize(prevSizes => (71 + (330 / (scrollTop * 0.161))) < 124 ? [(72 + (330 / (scrollTop * 0.161))), (26 + scrollTop * 0.161)] : prevSizes);
                    }
                    if (scrollTop > 0 && scrollTop < maxScollTop * 0.1) {
                        isVisible[0] === false && setIsVisible(q => { q[0] = true; q[1] = false; return q.map(w => w) });
                    } else if (scrollTop > maxScollTop * 0.1 && scrollTop < 0.35 * maxScollTop) {
                        isVisible[1] === false && setIsVisible(q => { q[1] = true; q[2] = false; return q.map(w => w) });
                    } else if (scrollTop > 0.4 * maxScollTop) {
                        isVisible[2] === false && setIsVisible(q => { q[2] = true; return q.map(w => w) });
                    }
                }
                return scrollTop
            })
            // setScrollTopChange(q => scrollTop === 0 ? 0 : scrollTop === maxScollTop ? 0 : q > 0 && q < -scrollTop ? scrollTop : q > -scrollTop ? -scrollTop : 0);
            // if (scrollTopChange > scrollTop) {
            //     if (0 < scrollTopChange && scrollTopChange > scrollTop && scrollTop !== 0 && logoSize[0] >= 72) {
            //         setLogoSize(prevSizes => [(124 - scrollTop * 0.161), (46 - scrollTop * 0.161)]);
            //     }
            //     if (scrollTop > viewportHeight * 0.1 && scrollTop < viewportHeight * 0.25) {
            //         isVisible[0] === true && setIsVisible(q => { q[0] = false; q[1] = true; return q.map(w => w) });
            //     } else if (scrollTop > viewportHeight * 0.3 && scrollTop < 0.5 * viewportHeight) {
            //         isVisible[1] === true && setIsVisible(q => { q[1] = false; q[2] = true; return q.map(w => w) });
            //     } else if (scrollTop > 0.5 * viewportHeight) {
            //         isVisible[2] === true && setIsVisible(q => { q[2] = false; return q.map(w => w) });
            //     }
            // } else {
            //     if (scrollTopChange < scrollTop && scrollTopChange <= maxScollTop && logoSize[0] < 128) {
            //         setLogoSize(prevSizes => (71 + (330 / (scrollTop * 0.161))) < 124 ? [(72 + (330 / (scrollTop * 0.161))), (26 + scrollTop * 0.161)] : prevSizes);
            //     }
            //     if (scrollTop > 0 && scrollTop < viewportHeight * 0.1) {
            //         isVisible[0] === false && setIsVisible(q => { q[0] = true; q[1] = false; return q.map(w => w) });
            //     } else if (scrollTop > viewportHeight * 0.1 && scrollTop < 0.3 * viewportHeight) {
            //         isVisible[1] === false && setIsVisible(q => { q[1] = true; q[2] = false; return q.map(w => w) });
            //     } else if (scrollTop > 0.3 * viewportHeight) {
            //         isVisible[2] === false && setIsVisible(q => { q[2] = true; return q.map(w => w) });
            //     }
            // }
        };
    }


    return (
        <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
            <video
                ref={videoRef}
                poster={`${process.env.PUBLIC_URL}/assets/membership/membership-info.jpg`}
                style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', height: '100%', objectFit: 'cover', transform: 'translate(-50%, -50%)' }}
                src={'https://img.positivehotel.io/2024/7/23/-5e28-4019-bd6a-fc1939ec37d9.mp4'} autoPlay loop muted playsInline
            />
            <div style={{ position: 'fixed', zIndex: 4, backgroundColor: "transparent", height: "50vh", width: "100%", pointerEvents: "none" }}></div>
            <div style={{ position: 'relative', zIndex: 2 }} onScrollCapture={handleScroll}>
                <div ref={scrollContainerRef} style={{ fontWeight: 600, fontSize: '14px', lineHeight: "24px", color: 'white', textAlign: 'center', padding: '40vh 20px 65vh 20px', height: '50px', overflow: 'scroll' }}>
                    <div style={{ marginBottom: "24px", position: logoSize[0] < 72 ? "absolute" : "unset", top: currentDeviceBuildVersion ? (Number(topHeight) ?? 0) + 15 : 15, left: 0, width: "100%" }}>
                        <PositivehotelLogoBig width={logoSize[0]} />
                    </div>
                    <div style={{ height: "54px", width: "100%", marginBottom: "24px", display: logoSize[0] < 72 ? "flex" : "none" }}>

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5vh" }}>
                        <div style={{ visibility: isVisible[0] ? 'visible' : "hidden", wordBreak: "keep-all" }}>
                            파지티브호텔은 건강한 식문화와 요가, 명상을 결합한
                            웰니스 프로그램을 통해 새로운 형태의 웰니스를 제시합니다
                            <br /><br />
                            우리가 추구하는 웰니스는 건강한 음식을 섭취하며
                            매일 규칙적으로 운동하는 삶을 기본으로 합니다
                        </div>
                        <div style={{ visibility: isVisible[1] ? 'visible' : "hidden" }}>
                            파지티브호텔에서 내면과 외면의 밸런스를 찾으며
                            지속 가능한 웰니스 라이프를 경험해 보세요
                        </div>
                        <div style={{ visibility: isVisible[2] ? 'visible' : "hidden" }}>
                            커뮤니티를 기반으로 다양한 취향을 지닌 사람들이 만나
                            진정한 행복에 대해 교류하며 가치를 나누는 과정에서
                            삶이 점점 나아지는 의식적 진화를 추구합니다
                        </div>
                    </div>
                </div>
            </div>
            <ArrowDown width={16} height={16} style={{ position: "fixed", bottom: "10vw", left: "50vw" }} stroke={"#FFFFFF"} />
        </div>
    )
}
export default MainPremiumMembershipContents