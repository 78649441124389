import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { getUrl, removeUrl } from "../../../service";
import MainFooter from "../../../layout/MainLayout/footer";
import { createEventCouponHistory } from "../../../service";
import { MoreOutlined } from "@ant-design/icons";
import { Divider, Modal } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { beUsedIssuedCouponById } from "../../../service";
import { requestTossPaymentConfirm } from "../../../service";
import { TopBar } from "../../../widgets";
// import { requestTossPaymentConfirm } from "../../../service";
declare module "react-router-dom" {
    interface URLSearchParams {
        get(name: MyQueryParams): string | null;
    }
}

export function SuccessPage() {
    const navigate = useNavigate();
    const [isSuccessOpenModal, setIsSuccessOpenModal] = useState<boolean>(false);
    const [isFailOpenModal, setIsFailOpenModal] = useState<boolean>(false);
    const [searchParams] = useSearchParams()
    const [payConfirmData, setPayConfirmData] = useState<ITossPayResult | undefined>(undefined);
    const orderId = searchParams.get("orderId");
    const amount = searchParams.get("amount")
    const paymentType = searchParams.get("paymentType");
    const paymentKey = searchParams.get("paymentKey");
    const usedPoint = searchParams.get("usedPoint");
    const selectedConselingBranch = searchParams.get("selectedConselingBranch");


    // 서버로 승인 요청
    useEffect(() => {
        requestServerTossPaymentConfirm();
    }, [])

    const requestServerTossPaymentConfirm = async () => {
        if (!orderId || !paymentKey || !amount || !usedPoint || !selectedConselingBranch) {
            console.log("orderId", orderId)
            console.log("paymentKey", paymentKey)
            console.log("amount", amount)
            console.log("usedPoint", usedPoint)
            console.log('paymentType', paymentType)
            console.log("selectedConselingBranch", selectedConselingBranch)
            return alert('필수값이 없습니다.');
        }
        try {
            const res = await requestTossPaymentConfirm(orderId, paymentKey, amount, Number(usedPoint), selectedConselingBranch)
            if (orderId.includes('_')) {
                //yogaCoupon 을 사용한 경우
                const resUsedIssuedCoupon = await beUsedIssuedCouponById(orderId.split('_')[1], res.data.id);
                console.log('resUsedIssuedCoupon', resUsedIssuedCoupon.data);
            } else if (orderId.split('-').length - 1 === 3) {
                // 코드 입력 쿠폰 사용한 경우
                const params = {
                    memberId: res.data.memberId,
                    tossPayId: res.data.id,
                    eventCouponId: orderId.split('-')[3] as string
                }
                createEventCouponHistory(params)
            }
            setIsSuccessOpenModal(true);
            setPayConfirmData(res.data);
        } catch (error) {
            console.error('error', error);
            setIsFailOpenModal(true);
        }
    }

    const clickPaymentFailButton = () => {
        const beforeUrl = getUrl();
        removeUrl();
        navigate(beforeUrl ? beforeUrl : "/ticket", { replace: true })
    }

    return (
        <>
            <TopBar title="결제" rightIcon={false} />
            <div style={{ backgroundColor: '#F2F2F2', height: '100vh', padding: '24px 20px' }}>
                <div style={{ marginTop: '20vh', textAlign: 'center', fontSize: '14px' }}>
                    <MoreOutlined rotate={90} style={{ fontSize: '50px', color: '#DDDDDD' }} />
                    <div style={{ fontSize: '22px', marginBottom: '30px', fontWeight: 600 }}>결제 중입니다.</div>
                    <div style={{ fontSize: "15px", color: '#555' }}>
                        화면을 벗어나면<br />
                        결제가 취소될 수 있습니다.
                    </div>
                </div>
            </div>

            <Modal centered open={isSuccessOpenModal} footer={[]} closeIcon={false}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 'bold', marginTop: '20px' }}>결제가 완료되었습니다</div>
                    <div style={{ marginTop: '5px', fontSize: '13px' }}>정기권은 마이페이지에서 확인할 수 있습니다.</div>
                </div>
                {payConfirmData && <>
                    <div style={{ backgroundColor: '#F4F4F4', padding: '15px', marginTop: '15px' }}>
                        <div style={{ fontWeight: 600, fontSize: '16px' }}>{payConfirmData?.orderName.replace("[강남, 도산, 선릉]", "[ALL PASS]")}</div>
                        <Divider style={{ margin: '10px' }} />
                        <div style={{ display: 'flex', color: '#555' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>결제 일자</div>
                            <div style={{ fontWeight: 400 }}>{dayjs().format('YYYY.MM.DD')}</div>
                        </div>
                        <div style={{ display: 'flex', color: '#555' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>결제 방법</div>
                            <div style={{ fontWeight: 400 }}>{payConfirmData?.method}</div>
                        </div>
                    </div>
                </>}
                <div style={{ backgroundColor: 'black', color: 'white', padding: '15px', borderRadius: '5px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                    onClick={() => navigate('/my', { replace: true })}>닫기</div>
            </Modal>

            <Modal centered open={isFailOpenModal} footer={[]} closeIcon={false}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 'bold', marginTop: '20px' }}>결제가 취소되었습니다</div>
                    <div style={{ marginTop: '5px', fontSize: '13px' }}>처음부터 다시 결제를 시도해 주세요.</div>
                </div>
                <div style={{ backgroundColor: 'white', color: 'black', border: '1px solid black', padding: '15px', borderRadius: '5px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                    onClick={() => clickPaymentFailButton()}>돌아가기</div>
            </Modal>
            <MainFooter />
        </>
    )
}