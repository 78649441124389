import { Flex, Skeleton } from "antd";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icon/arrow-right.svg"
import { useMyContext } from "../../../../entities/context";

const MySkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
        <div style={{ padding: '0 24px 340px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>

            <div style={{ width: '100%', marginTop: '45px' }}>
                <Flex style={{ width: '235px', alignItems: 'center' }} gap={4}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26 }} />
                    <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                </Flex>
            </div>

            <div style={{ width: '100%', marginTop: '45px' }}>
                <Flex>
                    <div style={{ width: '50%' }}>
                        <Flex style={{ width: '120px', alignItems: 'center' }} gap={4}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 19 }} />
                            <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                        </Flex>
                        <div style={{ fontSize: '22px', fontWeight: '600', marginTop: "5px" }}>{'-'}</div>
                    </div>
                    <div style={{ width: '50%' }}>
                        <Flex style={{ width: '120px', alignItems: 'center' }} gap={4}>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 19 }} />
                            <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                        </Flex>
                        <div style={{ fontSize: '22px', fontWeight: '600', marginTop: "5px" }}>{'-'}</div>
                    </div>
                </Flex>
            </div>

            <div style={{ marginTop: '32px', width: '160px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 19 }} />
            </div>

            <div style={{ marginTop: '28px', }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 182 }} />
            </div>

            <div style={{ marginTop: '33px', width: 160 }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 19 }} />
            </div>
            <div style={{ marginTop: '28px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 74 }} />
            </div>
            <div style={{ marginTop: 36 }}>
                <Flex style={{ alignItems: 'center' }} gap={16}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 28 }} />
                    <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                </Flex>
            </div>
        </div>
    </div>)
}

export default MySkeleton;