import { AxiosResponse } from 'axios';
import api from './Api'

export const getDefaultTicketListByCompanyId = (companyId: number): Promise<AxiosResponse<Array<IMobileDefaultTicket>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/company/${companyId}`)
}

export const getTicketListByYogaCenterId = (yogaCenterId: string): Promise<AxiosResponse<IMobileTicketByYogaCenter, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/${yogaCenterId}`)
}
export const getTicketForMobilePayById = (ticketId: string): Promise<AxiosResponse<TicketForMobilePay, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/pay-info/${ticketId}`)
}

export const getMyTicketByToken = (): Promise<AxiosResponse<Array<IMyTicket>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/token`);
}

export const getPopupTicketByLectureId = (lectureId: string): Promise<AxiosResponse<Array<IMobileDefaultTicket>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/popup/${lectureId}`)
}

