interface IProps {
    status: 'Complete' | 'Available' | 'Close'
}

const ReservationBadge = ({ status }: IProps) => {

    const style = () => {
        if (status === 'Complete') {
            return { backgroundColor: 'var(--primaryp400)' }
        } else if (status === 'Available') {
            return { border: '0.75px solid var(--secondarys500)', color: 'var(--secondarys700)' }
        } else if (status === 'Close') {
            return { backgroundColor: 'var(--secondarys200)', color: 'var(--secondarys400)' }
        }
    }

    return <div
        className="caption/c1"
        style={{ ...style(), padding: '4px 8px', borderRadius: '4px', lineHeight: '14px' }}
    >
        {status === 'Complete' ? '완료'
            : status === 'Available' ? '가능'
                : status === 'Close' ? '마감'
                    : ''}
    </div>
}
export { ReservationBadge }