import { useEffect, useRef, useState } from "react"
import { PaymentWidgetInstance, loadPaymentWidget } from "@tosspayments/payment-widget-sdk"
import { isLogin } from "../../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { getMemberDataByToken } from "../../../service"
import { removeUser } from "../../../service"
import { Button } from "antd"
import { removeUrl } from "../../../service"
import { TopBar } from "../../../widgets"

const clientKey = `${process.env.REACT_APP_TOS_PAY_CLIENT_KEY}`

const Checkout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [memberData, setMemberData] = useState<IMember | undefined>(undefined);
    const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
    const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance["renderPaymentMethods"]> | null>(null)
    const { price, orderId, orderName, couponId, pointValue, issuedYogaCouponId, selectedConselingBranch } = location.state;

    useEffect(() => {

        (async () => {

            if (!memberData) { return; }
            try {
                // ------  결제위젯 초기화 ------
                // 비회원 결제에는 customerKey 대신 ANONYMOUS를 사용하세요.
                const paymentWidget = await loadPaymentWidget(clientKey, memberData.mobile)  // 회원 결제
                // const paymentWidget = await loadPaymentWidget(clientKey, ANONYMOUS)  // 비회원 결제

                // ------  결제 UI 렌더링 ------
                // 결제 UI를 렌더링할 위치를 지정합니다. `#payment-method`와 같은 CSS 선택자와 결제 금액 객체를 추가하세요.
                // DOM이 생성된 이후에 렌더링 메서드를 호출하세요.
                // https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
                const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
                    "#payment-widget",
                    { value: price },
                    // 렌더링하고 싶은 결제 UI의 variantKey
                    // 아래 variantKey는 문서용 테스트키와 연동되어 있습니다. 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
                    // https://docs.tosspayments.com/guides/payment-widget/admin#멀티-결제-ui
                    { variantKey: "DEFAULT" }
                )

                // ------  이용약관 UI 렌더링 ------
                // 이용약관 UI를 렌더링할 위치를 지정합니다. `#agreement`와 같은 CSS 선택자를 추가하세요.
                // https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
                paymentWidget.renderAgreement(
                    '#agreement',
                    { variantKey: "AGREEMENT" } // 기본 이용약관 UI 렌더링
                )
                paymentWidgetRef.current = paymentWidget
                paymentMethodsWidgetRef.current = paymentMethodsWidget
            } catch (error) { console.error("Error: ", error) }

        })()
    }, [memberData])

    useEffect(() => { requestMemberData() }, [])
    const requestMemberData = async () => {

        if (!isLogin()) {
            return alert('로그인 후에 진행해주세요.');
        }

        try {
            const res = await getMemberDataByToken()
            setMemberData(res.data);
            removeUrl()
        } catch (error) {
            console.error('error', error);
            removeUser();
            navigate("/login");
        }

    }

    useEffect(() => {
        const paymentMethodsWidget = paymentMethodsWidgetRef.current

        if (paymentMethodsWidget == null) {
            return
        }

        // ------ 금액 업데이트 ------
        // 새로운 결제 금액을 넣어주세요.
        // https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
        paymentMethodsWidget.updateAmount(
            price
        )
    }, [price])

    // console.log('params : ', `orderId: ${orderId},orderName: ${orderName},customerName: ${memberData && memberData.name},customerEmail: ${memberData && memberData.email},customerMobilePhone: ${memberData && memberData.mobile}`)
    return (<>

        <TopBar title="주문서" rightIcon={false} />

        <div style={{ padding: '20px' }}>
            <h1>주문서</h1>
            <span>{`${price.toLocaleString()}원`}</span>
            <div>
                {/* <label>
                    <input
                        type="checkbox"
                        onChange={(event) => {
                            setPrice(event.target.checked ? price - 5000 : price + 5000)
                        }}
                    />
                    5,000원 할인 쿠폰 적용
                </label> */}
            </div>
            <div id="payment-widget" />
            <div id="agreement" />

            <Button
                type="primary"
                style={{ width: '100%', height: '50px' }}
                onClick={async () => {
                    const paymentWidget = paymentWidgetRef.current
                    try {
                        // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                        // 더 많은 결제 정보 파라미터는 결제위젯 SDK에서 확인하세요.
                        // https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
                        if (!memberData) return;
                        await paymentWidget?.requestPayment({
                            orderId: orderId + `-${memberData.mobile}${couponId ? couponId !== '' && '-' + couponId : ''}${issuedYogaCouponId ? issuedYogaCouponId !== '' && '_' + issuedYogaCouponId : ''}`,
                            orderName: orderName,
                            customerName: memberData.name,
                            customerEmail: memberData.email,
                            customerMobilePhone: memberData.mobile,
                            successUrl: `${window.location.origin}/success?usedPoint=${pointValue}&selectedConselingBranch=${selectedConselingBranch}`,
                            failUrl: `${window.location.origin}/fail`,
                        })
                    } catch (error: any) { console.error("Error: ", error); alert(error?.message ?? "오류가 발생했습니다. 다시 시도해 주세요."); navigate(-1); }
                }}
            >
                결제하기
            </Button>
        </div>
    </>)
}
export default Checkout;