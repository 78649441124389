import { AxiosResponse } from 'axios';
import api from './Api'

export const getKakaoUser = (code: string): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/kakao', { authorizationCode: code })
}
export const getKakaoUserByAccessToken = (accessToken: string): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/kakao/access-token', { accessToken: accessToken })
}
export const getNaverUser = (code: string): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/naver', { authorizationCode: code })
}
export const getNaverUserByAccessToken = (naverTokenRequest: INaverToken): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/naver/access-token', naverTokenRequest)
}
export const getRefreshTokenTest = (): Promise<AxiosResponse<any, any>> => {
    return api.get('/auth/test');
}
export const getAppleUserData = (authorizationCode: string): Promise<AxiosResponse<IAppleUserInfo, any>> => {
    return api.post('/auth/apple', { authorizationCode: authorizationCode })
}
