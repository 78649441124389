import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { LectureByCenterAction } from './types';
import { getYogaLectureListByRangeDateAndYogaCenterId } from '../../service';
import dayjs from 'dayjs';
import { getLectureListByCenterAsync } from './actions';

export function getLectureListByCenterThunk(yogaCenterId: number, selectedDate: string, endDate: string): ThunkAction<void, RootState, null, LectureByCenterAction> {
    return async dispatch => {
        const { request, success, failure } = getLectureListByCenterAsync;
        // dispatch(request());
        try {
            const lectureByCenterList = await getYogaLectureListByRangeDateAndYogaCenterId(endDate, yogaCenterId);
            lectureByCenterList.data.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)))
            dispatch(success({ centerId: yogaCenterId, data: lectureByCenterList.data, selectedDate: selectedDate }));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};

export function updateLectureParamsThunk(yogaCenterId: number, selectedDate: string, data: Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | null): ThunkAction<void, RootState, null, LectureByCenterAction> {
    return async dispatch => {
        const { request, success, failure } = getLectureListByCenterAsync;
        // dispatch(request());
        try {
            data?.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)));
            dispatch(success({ centerId: yogaCenterId, data: data, selectedDate: selectedDate }));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};