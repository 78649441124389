import { AxiosResponse } from 'axios';
import api from './Api'

export const getEventCouponAvailableByCodeAndTicket = (code: string, ticketId: string): Promise<AxiosResponse<IEventCoupon, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/eventCoupon/${code}/${ticketId}`);
}


export const checkEventCouponAvailableByCodeAndTicket = (code: string, ticketId: string): Promise<AxiosResponse<IEventCoupon, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/eventCoupon/check/${code}/${ticketId}`);
}