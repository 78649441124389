import { useEffect, useState } from 'react'
import HomeSkeleton from '../../components/skeleton/home/homeSkeleton'
import { TopBar } from '../../widgets'
import { BannerCarousel, NoticePopupModal } from '../../features'
import { GnbFooter, CenterCard, ShowEnvBadge } from "../../shared"
import { requestStandardVersion, storedFCMTokenForMobile } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as PositivehotelYogaLogo } from "../../assets/images/positivehotelYogaLogo.svg"
import { ICenterCard, centerCardList } from "./model"

const Home = () => {
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > (65 + Number())) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);
    useEffect(() => {
        storedFCMTokenForMobile()
        requestStandardVersion()
    }, [])
    useEffect(() => { imageLoaded && setIsInitLoading(false) }, [imageLoaded])
    window.reactNavigator = (pageName: string) => { navigate(pageName) }

    return <>
        {isInitLoading && <HomeSkeleton />}
        <ShowEnvBadge />
        <TopBar type='logo' thema={scrolled ? 'dark' : 'white'} position='fixed' />
        <BannerCarousel setImageLoaded={setImageLoaded} />
        <div style={{ paddingInline: 20, marginBottom: '40px' }}>
            <div style={{ marginTop: "32px" }}>
                <PositivehotelYogaLogo />
            </div>
            {centerCardList.map((centerCard: ICenterCard, idx: number) =>
                <CenterCard
                    key={idx}
                    imageUrl={centerCard.imageUrl}
                    title={centerCard.title}
                    content={centerCard.content}
                    to={centerCard.to}
                    style={{ marginTop: 32 }} />)}
        </div>
        {!isInitLoading && <NoticePopupModal />}
        <GnbFooter activeButton='home' />
    </>
}

export default Home