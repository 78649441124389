import { useEffect, useState } from "react";
import LecutreDetailSkeleton from "../../components/skeleton/lecture/lectureDetailSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { getMemberForReservationByToken, getYogaLectureById } from "../../service";
import { isLogin, lectureDatePeriodFormat } from "../../utils";
import { ProgramInfoWithModal } from "./ui/programInfoWithModal";
import { Flex } from "antd";
import { LectureInfoRow } from "./ui/lectureInfoRow";
import dayjs from "dayjs";
import { BottomBtn, DividerThin } from "../../shared";
import { ClassDescription } from "./ui/classDescription";
import { ReactComponent as ArrowRight } from '../../assets/images/icon/arrow-right.svg';
import { MyIssuedTicketAvaiable } from "./ui/myIssuedTicketAvaiable";
import { CancelReservationWithModalInLectureDetail } from "./ui/modal/cancelReservationWithModalInLectureDetail";
import { ReservationWithModalInLectureDetail } from "./ui/modal/reservationWithModalInLectureDetail";
import { TopBar } from "../../widgets";
import { useMyContext } from "../../entities/context";

const LectureDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [yogaLecture, setYogaLecture] = useState<IYogaLectureByIdAndMemberId>();
    const [issuedTicketAvailable, setIssuedTicketAvailable] = useState<IMobileIssuedTicketGetResponse>();
    const [selectedIssuedTicketId, setSelectedIssuedTicketId] = useState<number>();
    const { topHeight } = useMyContext();

    useEffect(() => {
        requestYogaLectureById();
    }, [id])

    const requestYogaLectureById = async () => {
        if (!id) return;
        try {
            const resYogaLecture = await getYogaLectureById(id);
            setYogaLecture(resYogaLecture.data)
            const img = new Image();
            img.src = resYogaLecture.data ? resYogaLecture.data.yogaClassThumbnailImage : `${process.env.PUBLIC_URL}/assets/main.jpg`
            img.onload = () => {
                setImageLoaded(true);
            }

            if (!isLogin()) return;

            const resMember = await getMemberForReservationByToken(resYogaLecture.data.yogaClassId);
            setIssuedTicketAvailable(resMember.data)
            const mobileIssuedTicketGetResponseList = resMember.data;
            if (mobileIssuedTicketGetResponseList.mobileIssuedMyTicketList.length > 0) setSelectedIssuedTicketId(mobileIssuedTicketGetResponseList.mobileIssuedMyTicketList.filter((issuedTicket) => !(dayjs(issuedTicket.startDate).set('h', 0).set('minute', 0).isAfter(resYogaLecture.data.startDateTime) || dayjs(issuedTicket.expireDate).set('h', 23).set('minute', 59).isBefore(resYogaLecture.data.startDateTime)))[0]?.id);
        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => { if (imageLoaded) setIsInitLoading(false) }, [imageLoaded])

    return <>
        {isInitLoading && <LecutreDetailSkeleton />}
        <TopBar
            position='fixed'
            title="수업 상세"
            rightIcon={false}
            style={{ backgroundColor: 'var(--basewhite)' }}
        />

        {yogaLecture && <>
            <img
                style={{ width: "100%", objectFit: 'cover', marginTop: (Number(topHeight) + 48 + 'px') }}
                alt="Not"
                src={(yogaLecture.yogaClassThumbnailImage && yogaLecture.yogaClassThumbnailImage !== '')
                    ? yogaLecture.yogaClassThumbnailImage
                    : `${process.env.PUBLIC_URL}/assets/main.jpg`} />

            <div style={{ padding: '24px 20px' }}>
                {yogaLecture.yogaCenterName !== "강남" &&
                    <ProgramInfoWithModal
                        name={yogaLecture.yogaProgramName}
                        description={yogaLecture.yogaProgramDescription}
                        title={yogaLecture.yogaProgramName}
                    />
                }
                <div className="title/t2" style={{ marginTop: 8 }}>{yogaLecture.yogaClassName}</div>
                <div style={{ height: 20 }}></div>

                <LectureInfoRow label="시간" value={lectureDatePeriodFormat(yogaLecture.startDateTime, yogaLecture.endDateTime)} />
                <LectureInfoRow label="장소" value={`${yogaLecture.yogaCenterName} ${yogaLecture.room}`} />
                <LectureInfoRow label="코치" value={`${yogaLecture.teacherName} 코치`} />

                <ClassDescription description={yogaLecture.description} />
                <DividerThin style={{ marginBlock: 20 }} />

                <Flex
                    style={{ justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => navigate(`/teacher/${yogaLecture.teacherId}`)}>
                    <div>
                        <div className="sub-title/st3">{yogaLecture.teacherName} 코치</div>
                        <div className="body/b1 secondary/s700" style={{ marginTop: 8 }}>코치 소개 보기</div>
                    </div>
                    <ArrowRight width={16} height={16} stroke="#555555" style={{ paddingInline: 8 }} />
                </Flex>

                <DividerThin style={{ marginBlock: 20 }} />

                <MyIssuedTicketAvaiable
                    title={`사용 가능 ${yogaLecture.yogaProgramName.includes('Positive COMMUNITY') ? '수강권' : '정기권'}`}
                    ticket={issuedTicketAvailable}
                    isReservation={yogaLecture.isReservation}
                    lectureStartDateTime={yogaLecture.startDateTime}
                    selectedId={selectedIssuedTicketId}
                    setSelectedId={setSelectedIssuedTicketId} />
            </div>

            {!issuedTicketAvailable
                ? <BottomBtn text="먼저 로그인 하기" to={'/login'} />
                : dayjs().isAfter(yogaLecture.startDateTime)
                    ? <BottomBtn text="이미 지난 수업 입니다." />
                    : yogaLecture.isReservation
                        ? <CancelReservationWithModalInLectureDetail
                            yogaLecture={yogaLecture}
                            memberId={issuedTicketAvailable.memberId}
                            requestYogaLectureById={requestYogaLectureById} />
                        : yogaLecture.nowReservationCnt >= yogaLecture.maxReservationMember
                            ? <BottomBtn text="정원 초과" />
                            : selectedIssuedTicketId
                                ? <ReservationWithModalInLectureDetail
                                    yogaLecture={yogaLecture}
                                    memberId={issuedTicketAvailable.memberId}
                                    requestYogaLectureById={requestYogaLectureById}
                                    issuedTicketId={selectedIssuedTicketId} />
                                : issuedTicketAvailable.mobileIssuedMyTicketList.length === 0
                                    ? <>{yogaLecture.yogaProgramName.includes('Positive COMMUNITY') ?
                                        <BottomBtn text="수강권 구매하러 가기" to={`/popup/ticket/${id}`} /> :
                                        <BottomBtn text="정기권 구매하러 가기" to={`/ticket`} />}
                                    </>
                                    : <BottomBtn text="정기권을 선택해 주세요." />
            }
        </>}
    </>
}

export { LectureDetail }