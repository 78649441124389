import { Link, useLocation } from "react-router-dom"
import { ReactComponent as ArrowRight } from '../../../../assets/images/icon/arrow-right.svg'
import { Flex } from "antd"
import { ToLinkBarWithTitleArrow, MemberBenefitInfo, RequestComponent } from "../../../../shared"
import { setUrl } from "../../../../service"

const MyBeforeLogin = () => {
    const location = useLocation();
    const LonginLinkonClick = () => {
        setUrl(location.pathname);
    }
    return (
        <>
            <div style={{ paddingInline: 'var(--space20)' }}>
                <div style={{ marginTop: 43 }}>
                    <Link
                        to="/login"
                        className="sub-title/st4 base/black"
                        style={{ textDecoration: 'none', display: "flex", alignItems: "center", gap: 4 }}
                        onClick={LonginLinkonClick}>
                        로그인 · 회원가입 <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </Link>
                </div>
                <Flex style={{ justifyContent: 'space-between', textAlign: 'left', marginTop: 24 }}>
                    <div style={{ width: '100%' }}>
                        <MemberBenefitInfo title="멤버십" value={"-"} to={'/login'} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <MemberBenefitInfo title={<>포인트<span style={{ lineHeight: "16px", padding: "0px 3px" }}>·</span> 쿠폰</>} value={"-"} to={'/my/reward'} />
                    </div>
                </Flex>

                <div className="title/t1" style={{ marginTop: 24, padding: '8px 0' }}>
                    보유 정기권
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ textAlign: 'center', backgroundColor: "var(--secondarys100)", padding: '60px 0' }}>
                        <div className="body/b2" style={{ color: "var(--secondarys600)" }}>
                            로그인 후 이용해 주세요.
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <Link className="btn/text-btn" to={'/login'} style={{ color: 'var(--secondarys800)', textDecoration: 'underline' }} onClick={LonginLinkonClick}>
                                로그인 ・ 회원가입하기
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="title/t1" style={{ marginTop: 24, padding: '8px 0' }}>
                    다가오는 프로그램
                </div>

                <div style={{ marginTop: 20 }}>
                    <RequestComponent text="로그인 후 이용해 주세요." />
                </div>

                <div style={{ marginTop: 20 }}>
                    <ToLinkBarWithTitleArrow to={'/login'} title="정기권 구매" />
                </div>
            </div>
        </>
    )
}
export { MyBeforeLogin }