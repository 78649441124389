import { AxiosResponse } from "axios"
import api from "./Api"

export const getYogaLectureById = (id: string): Promise<AxiosResponse<IYogaLectureByIdAndMemberId, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/yoga-lecture/${id}`)
}

export const getYogaLectureListByRangeDateAndYogaCenterId = (endDate: string, yogaCenterId: number): Promise<AxiosResponse<Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/yoga-lecture/yoga-center?endDate=${endDate}&yogaCenterId=${yogaCenterId}`)
}

export const getMyLectureByTeacherId = (startDate: string, endDate: string, teacherId: string): Promise<AxiosResponse<Array<IMyLecture>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/yoga-lecture/teacher?startDate=${startDate}&endDate=${endDate}&teacherId=${teacherId}`);
}