import { createReducer } from 'typesafe-actions';
import { LectureByCenterState, LectureByCenterAction } from './types';
import {
    GET_LECTURELISTBYCENTER,
    GET_LECTURELISTBYCENTER_SUCCESS,
    GET_LECTURELISTBYCENTER_ERROR
} from './actions';


const initialState: LectureByCenterState = {
    lectureByCenterList: {
        lectureByCenterLoading: false,
        lectureByCenterError: null,
        lectureByCenterData: null
    }
};

const lectureByCenter = createReducer<LectureByCenterState, LectureByCenterAction>(initialState, {
    [GET_LECTURELISTBYCENTER]: state => ({
        ...state,
        lectureByCenterList: {
            lectureByCenterLoading: true,
            lectureByCenterError: null,
            lectureByCenterData: null
        }
    }),
    [GET_LECTURELISTBYCENTER_SUCCESS]: (state, action) => ({
        ...state,
        lectureByCenterList: {
            lectureByCenterLoading: false,
            lectureByCenterError: null,
            lectureByCenterData: action.payload
        }
    }),
    [GET_LECTURELISTBYCENTER_ERROR]: (state, action) => ({
        ...state,
        lectureByCenterList: {
            lectureByCenterLoading: false,
            lectureByCenterError: action.payload,
            lectureByCenterData: null
        }
    }),
});

export default lectureByCenter;