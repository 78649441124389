import { Carousel as CarouselAntd } from "antd"
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ICarousel {
    imageUrl: string;
    linkUrl: string;
}
interface IPops {
    carouselList?: ICarousel[];
    imgSize?: { x: number, y: number };
    style?: React.CSSProperties;
}

const Carousel = ({ carouselList, imgSize, style }: IPops) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const onChange = (currentSlide: number) => { setCurrentSlide(currentSlide); };
    const navigate = useNavigate();

    return <>
        {carouselList && carouselList?.length > 0 && <>
            <CarouselAntd draggable afterChange={onChange} autoplay dots={false}>
                {carouselList?.map((carouse: ICarousel, idx) => <div key={idx}>
                    <div style={{ width: '100%', backgroundImage: `url(${carouse.imageUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', paddingBottom: imgSize ? `${(imgSize.y / imgSize.x) * 100}%` : `${(1.6 / 1) * 100}%`, ...style }}
                        onClick={() => navigate(carouse.linkUrl)} />
                </div>)}
            </CarouselAntd>
            {carouselList && carouselList?.length > 1 &&
                <div style={{ width: '100%', display: 'flex' }}>
                    {carouselList?.map((_carouse, index: number) => <div style={{ width: `calc(100% / ${carouselList?.length})`, backgroundColor: currentSlide >= index ? 'black' : '#E9E9E9', height: '2px' }} />)}
                </div>
            }
        </>}
    </>
}
export { Carousel }