import { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MainBiBclack } from "../../assets/images/MainBiBclack.svg";
import { isLogin } from "../../utils";
import { getAppleUserData } from "../../service";
import { ReactComponent as Close } from "../../assets/images/icon/close.svg"
import { ReactComponent as KakaoLoginButton } from "../../assets/images/kakaoLoginButton.svg"
import { ReactComponent as NaverLoginButton } from "../../assets/images/naverLoginButton.svg"
import { ReactComponent as AppleLoginButton } from "../../assets/images/appleLoginButton.svg"
import { DevLogin } from "../../shared";

const Login = () => {
    const KAKAO_AUTH_URL = `kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI}&response_type=code&lang=ko&scope=phone_number,birthday,birthyear,gender,profile,account_email,name`;
    const NAVER_AUTH_URL = `nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URI}&state=${process.env.REACT_APP_NAVER_LOGIN_STATE}&lang=ko_KR`;
    const [currentDeviceBuildVersion, setCurrentDeviceBuildVersion] = useState<boolean>(false);
    const [topHeight, setTopHeight] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }));

            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    setCurrentDeviceBuildVersion(true)
                    window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getStatusBarTopHeight', data: {} }));
                    window.getStatusBarTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true) }
                }
            }
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }))

            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getSafeAreaTopHeight', data: {} }))
                    window.getSafeAreaTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true); }
                }
            }
        }
    }, [])

    useEffect(() => {
        if (isLogin()) {
            navigate("/my")
            return;
        }
        window.getAppleAuthCode = (authorizationCode: string, name: string, email: string) => {
            // alert('authorizationCode: ' + authorizationCode);
            // navigate("/applelogin/callback")

            getAppleUserData(authorizationCode)
                .then((res) => console.log('res', res.data))
                .catch((error) => console.error('error', error));
        }
    }, [])


    const navigate = useNavigate();
    const clickKakaoLogin = () => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({
                functionName: 'kakaoLogin',
                data: {}
            }));
            return;
        }

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
                functionName: 'kakaoLogin',
                data: {}
            }))
            return;
        }

        window.location.href = "https://" + KAKAO_AUTH_URL;
    }

    const clickNaverLogin = () => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({
                functionName: 'naverLogin',
                data: {}
            }));
            return;
        }

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
                functionName: 'naverLogin',
                data: {}
            }))
            return;
        }

        navigate("//" + NAVER_AUTH_URL);
    }

    const clickAppleLogin = () => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
                functionName: 'appleLogin',
                data: {}
            }))
            window.getAppleAuthCode = (code: string, name: string, emali: string) => {
                navigate("/applelogin/callback?code=" + code)
            }
        }
    }


    return <>
        <div style={{ position: 'relative', height: '100vh', backgroundColor: "#FFFFFF" }}>
            <div style={{ textAlign: 'center', position: 'absolute', top: '0', width: '100%' }}>
                <div style={{ width: "15%", marginTop: currentDeviceBuildVersion ? topHeight + "px" : 0 }} >
                    <Close width={24} height={24} stroke={"#000000"} style={{ color: 'black', fontSize: '24px', padding: '12px', marginTop: "17px", cursor: "pointer" }} onClick={() => navigate("/")} />
                </div>
                <DevLogin />
            </div>
            <div style={{ textAlign: 'center', position: 'absolute', top: '20vh', width: '100%' }}>
                <MainBiBclack style={{ marginTop: "28.2%", width: '38%', height: '6.4%', cursor: "pointer" }} onClick={() => navigate("/")} />
            </div>
            <div style={{ textAlign: 'center', position: 'absolute', bottom: '18vh', width: '100%' }}>
                <div>
                    <KakaoLoginButton style={{ marginBottom: "20px", cursor: "pointer" }} onClick={clickKakaoLogin} />
                    <NaverLoginButton style={{ marginBottom: "20px", cursor: "pointer" }} onClick={clickNaverLogin} />
                    {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) &&
                        <AppleLoginButton style={{ cursor: "pointer" }} onClick={clickAppleLogin} />
                    }
                    <div style={{ marginTop: "27px", fontSize: "13px", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/")}>로그인 전 둘러보기</div>
                </div>

            </div>
        </div>
    </>
}
export default Login;