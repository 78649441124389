import { useEffect, useState } from "react";
import { BottomBtn, BottomSheet, DividerThin, InlineTicketReceipt, TicketCancleModal, TicketReceipt } from "../../shared"
import { converteConselingBranch, formatPhoneNumber } from "../../utils";
import PayInfoSkeleton from "../../components/skeleton/pay/payInfoSkeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CirleClose } from "../../assets/images/icon/circle-close.svg";
import { ReactComponent as CircleCheck } from "../../assets/images/icon/circle-check.svg";
import { TopBar } from "../../widgets";
import { checkEventCouponAvailableByCodeAndTicket, createIssuedTicketEventCouponWithoutMoney, getEventCouponAvailableByCodeAndTicket, getTicketForMobilePayById, setUrl } from "../../service";
import { InputNumber } from "antd";
import { UseCouponByPayInfo } from "../../features";

const PayInfo = () => {
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { ticketId } = useParams();
    const [ticketCancelModalStatus, setTicketCancelModalStatus] = useState<boolean>(false);
    const [ticketData, setTicketData] = useState<TicketForMobilePay | undefined>(undefined);
    const [pointValue, setPointValue] = useState<number>();
    const [eventCouponInfo, setEventCouponInfo] = useState<IEventCoupon>();
    const [couponId, setCouponId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [yogaCouponModalStatus, setYogaCouponModalStatus] = useState<boolean>(false);
    const [conselingBranchModalStatus, setConselingBranchModalStatus] = useState<boolean>(false);
    const [selectYogaCoupon, setSelectYogaCoupon] = useState<IYogaCouponAvailable | undefined>(undefined);
    const [selectedConselingBranch, setSelectedConselingBranch] = useState<"SEOLLEUNG" | "DOSAN" | "GANGNAM">("SEOLLEUNG");
    const [isInfoCheck, setIsInfoCheck] = useState<boolean>(false);
    const [isCancelCheck, setIsCancelCheck] = useState<boolean>(false);
    const [isPaying, setIsPaying] = useState<boolean>(false);
    useEffect(() => {
        if (!ticketId) return;
        getTicketForMobilePayById(ticketId)
            .then(res => {
                setTicketData(res.data);
                setSelectedConselingBranch(converteConselingBranch(res.data.yogaCenterName))
            })
            .catch(err => { console.error(err); })
            .finally(() => setIsInitLoading(false))
    }, [])
    const selectYogaCouponClick = (yogaCouponData: IYogaCouponAvailable | undefined) => {
        setCouponId('');
        setSelectYogaCoupon(yogaCouponData);

        if (!ticketData) return;
        let newPrice;
        if (!yogaCouponData) {
            newPrice = ticketData.price
        } else {
            newPrice = ticketData.price - (ticketData.price * yogaCouponData.discountValue * 0.01);
        }

        const newTicketData = {
            ...ticketData,
            discountedPrice: newPrice,
        }
        setTicketData(newTicketData);
    }
    const clickCouponApply = async (couponCode: string) => {
        setSelectYogaCoupon(undefined);
        if (!couponCode) return alert("코드를 입력해 주세요");
        if (!ticketId) return;
        setLoading(true);
        const res = await getEventCouponAvailableByCodeAndTicket(couponCode, ticketId);
        setEventCouponInfo(res.data);
        if (res.data.id === 0) {
            const res = await checkEventCouponAvailableByCodeAndTicket(couponCode, ticketId);
            alert(res.data);
            setLoading(false);
            return;
        }
        setLoading(false);
        setYogaCouponModalStatus(false);
        if (!ticketData) return;
        let newPrice;
        if (res.data.type === '비율') {
            newPrice = ticketData.price - (ticketData.price * res.data.discount);
        } else {
            newPrice = ticketData.discountedPrice - res.data.discount;
        }
        const newTicketData = {
            ...ticketData,
            discountedPrice: newPrice,
        }
        setCouponId(res.data.id.toString());
        setTicketData(newTicketData);
    }

    const clickPaymentButton = () => {
        if (!ticketData) return;
        if (isPaying) return;
        let today = new Date();
        const state = {
            price: pointValue ? ticketData.discountedPrice - pointValue : ticketData.discountedPrice,
            orderId: `${ticketId}-${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`,
            orderName: `[${ticketData.yogaCenterName}] ${ticketData.ticketName}`,
            couponId: couponId,
            issuedYogaCouponId: selectYogaCoupon?.issuedYogaCouponId,
            pointValue: pointValue ?? 0,
            selectedConselingBranch: selectedConselingBranch ? selectedConselingBranch : "SEOLLEUNG",
        }
        if (state.price === 0) {
            if (!ticketId) return;
            setIsPaying(true)
            createIssuedTicketEventCouponWithoutMoney({ ticketId, eventCouponId: eventCouponInfo?.id, usedPoint: pointValue ?? 0, issuedYogaCouponId: selectYogaCoupon?.issuedYogaCouponId })
                .then(res => res.data === true && navigate('/my'))
                .catch(e => console.error('e', e))
                .finally(() => setIsPaying(false));

            return;
        }

        setUrl(location.pathname)

        navigate('/checkout', { state: state })
    }
    return <>
        {isInitLoading && <PayInfoSkeleton />}
        <TopBar title="결제 확인" rightIcon={false} />
        <div style={{ padding: '24px var(--space20)' }}>
            {ticketData && <TicketReceipt ticket={{ ...ticketData, startDate: ticketData.period.slice(0, 10), expireDate: ticketData.period.slice(13) }}>
                <div className="title/t1">회원 정보 확인</div>
                <InlineTicketReceipt title={"이름"} content={ticketData.memberName} style={{ marginTop: 'var(--space20)' }} />
                <DividerThin />
                <InlineTicketReceipt title={"연락처"} content={formatPhoneNumber(ticketData.mobile)} />
                <DividerThin />
                <InlineTicketReceipt title={"이메일"} content={ticketData.email} />

                <div className="title/t1 base/black" style={{ marginTop: 'var(--space40)' }}>정기권 정보</div>
                <InlineTicketReceipt title={"상담 지점"}
                    content={<BottomSheet
                        title={'지점 선택'}
                        isShowLabel
                        dataList={[{ id: 1, label: '선릉', value: 'SEOLLEUNG' }, { id: 2, label: '도산', value: 'DOSAN' }, { id: 3, label: '강남', value: 'GANGNAM' }]}
                        isOpen={conselingBranchModalStatus}
                        setIsOpen={setConselingBranchModalStatus}
                        value={selectedConselingBranch}
                        setValue={setSelectedConselingBranch} />
                    }
                    style={{ marginTop: 'var(--space20)' }} />
                <DividerThin />
                <InlineTicketReceipt title={"사용 가능 지점"} content={ticketData.yogaCenterName} />
                <DividerThin />
                <InlineTicketReceipt title={"수강권명"} content={ticketData.ticketName} />
                <DividerThin />
                <InlineTicketReceipt title={"사용기한"} content={ticketData.period} />

                <div className="title/t1 base/black" style={{ marginTop: 'var(--space40)' }}>결제 정보</div>
                <InlineTicketReceipt title={"수강권 금액"} content={ticketData.discountedPrice ? ticketData.discountedPrice.toLocaleString() + '원' : '0원'} style={{ marginTop: 'var(--space20)' }} />
                <DividerThin />
                <InlineTicketReceipt title={"회당 금액"} content={(ticketData.discountedPrice / ticketData.totalUsableCnt) ? (ticketData.discountedPrice / ticketData.totalUsableCnt).toLocaleString() + '원' : '0원'} />
                <DividerThin />
                <InlineTicketReceipt title={"포인트"}
                    content={<div style={{ textAlign: 'right', position: 'relative' }}>
                        <div className="body/b2">사용 가능 <span className="primary/p800" >{ticketData.memberPoint ? ticketData.memberPoint?.toLocaleString() : 0}원</span></div>
                        <InputNumber
                            style={{ width: '148px', marginTop: '12px', height: '32px' }}
                            min={0}
                            max={!ticketData.memberPoint ? 0 : ((ticketData.memberPoint < ticketData.discountedPrice) ? (ticketData.memberPoint < 0 ? 0 : ticketData.memberPoint) : (((ticketData.discountedPrice) < 0) ? 0 : ticketData.discountedPrice))}
                            value={pointValue}
                            placeholder="사용할 포인트"
                            onChange={(value: number | null) => value ? setPointValue(value) : setPointValue(0)}
                            controls={false}
                        />
                        <CirleClose width={16} height={16} stroke="var(--secondarys400)" onClick={() => { setPointValue(undefined) }} style={{ position: 'absolute', cursor: 'pointer', top: 44, right: 16 }} />
                    </div>}
                />
                <DividerThin />
                <InlineTicketReceipt
                    title={"쿠폰"}
                    style={{ paddingBlock: 'var(--space16)' }}
                    content={
                        <UseCouponByPayInfo
                            yogaCouponModalStatus={yogaCouponModalStatus}
                            setYogaCouponModalStatus={setYogaCouponModalStatus}
                            yogaCouponDataList={ticketData.yogaCouponAvailableList}
                            selectYogaCouponClick={selectYogaCouponClick}
                            selectYogaCoupon={selectYogaCoupon}
                            clickCouponApply={clickCouponApply}
                            couponId={couponId}
                            loading={loading}
                        />}
                />

                {(couponId && eventCouponInfo) && <InlineTicketReceipt title={eventCouponInfo.name} content={'-' + (ticketData.price * eventCouponInfo.discount).toLocaleString() + '원'} styles={{ title: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, content: { whiteSpace: 'nowrap' } }} />}
                {selectYogaCoupon && <InlineTicketReceipt title={selectYogaCoupon.name} content={'-' + (ticketData.price * selectYogaCoupon.discountValue * 0.01).toLocaleString() + '원'} styles={{ title: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, content: { whiteSpace: 'nowrap' } }} />}
                <DividerThin />
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 'var(--space20) var(--space12)' }} >
                    <div className="sub-title/st2 secondary/s800" >총 결제 금액</div>
                    <div className="sub-title/st3 base/black" >{((ticketData.discountedPrice - (pointValue ?? 0))?.toLocaleString() ?? 0) + '원'}</div>
                </div>

            </TicketReceipt>}
            <div style={{ marginTop: 'var(--space16)' }}>
                <div style={{ display: 'flex', gap: 'var(--space12)', width: '244px', cursor: 'pointer' }} onClick={() => setIsInfoCheck(q => !q)}>
                    <CircleCheck width={20} height={20} stroke={isInfoCheck ? "var(--basewhite)" : "var(--secondarys400)"} fill={isInfoCheck ? "var(--baseblack)" : "var(--basewhite)"} style={{ paddingBlock: 'var(--space2)' }} />
                    <div className={"body/b2 base/black"}>
                        위 정보를 확인했으며 이에 동의합니다.
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 'var(--space12)', width: '228px', marginTop: 8 }}>
                    <CircleCheck width={20} height={20} stroke={isCancelCheck ? "var(--basewhite)" : "var(--secondarys400)"} fill={isCancelCheck ? "var(--baseblack)" : "var(--basewhite)"}
                        style={{ paddingBlock: 'var(--space2)', cursor: 'pointer' }}
                        onClick={() => setIsCancelCheck(q => !q)} />
                    <div style={{ display: 'flex', gap: 'var(--space8)' }}>
                        <div className={"body/b2 base/black"} style={{ cursor: 'pointer' }} onClick={() => setIsCancelCheck(q => !q)}>
                            취소 정책에 동의합니다.
                        </div>
                        <TicketCancleModal openModal={ticketCancelModalStatus} setOpenModal={setTicketCancelModalStatus} />
                    </div>
                </div>
            </div>
        </div>
        <BottomBtn disabled={!(isInfoCheck && isCancelCheck && !isPaying)} text={isPaying ? '결제 중입니다.' : (((ticketData?.discountedPrice ?? 0) - (pointValue ?? 0))?.toLocaleString() ?? 0) + ' 원 결제하기'} onClick={clickPaymentButton} />
    </>
}

export { PayInfo }