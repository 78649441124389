import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import './index.css'
import { getEventById } from "../../../../service";
import TagHead from "../../../../components/tag/head";
import MainHeader from "../../../../layout/MainLayout/header";
import MyEventDetailSkeleton from "../../../../components/skeleton/my/myEventDetailSkeleton";
import { TopBar } from "../../../../widgets";

const EventDetail = () => {
    const { id } = useParams();
    const [eventData, setEventData] = useState<IEventBoardDetail>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;
        setLoading(true)
        getEventById(id).then(res => {
            setLoading(false);
            setEventData(res.data);
        }).catch(err => console.error(err))
    }, [])
    return <>

        <TagHead title={eventData?.title ?? ''} imgUrl={eventData?.thumbnailImgUrl ?? ''} description="positivehotel.com" />
        <TopBar title="" rightIcon={false} />

        {(loading || !eventData) ? <MyEventDetailSkeleton /> : <div dangerouslySetInnerHTML={{ __html: eventData.htmlData ?? '' }} />}
    </>
}

export default EventDetail;