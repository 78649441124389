import { Fragment, useEffect, useState } from "react";
import { TopBar } from "../../../widgets";
import { NavigateLogin, DividerThin, BottomSheet, PaidHistoryCard } from "../../../shared";
import { getIssuedTicketPaymentListByMemberIdAndPeriod } from "../../../service";
import { isLogin } from "../../../utils";
import MyCheckInSkeleton from "../../../components/skeleton/my/myCheckInSkeleton";
import { useParams } from "react-router-dom";

const PaidHistory = () => {
    const bottomSheetDataList: IBottomSheet[] = [{ id: 1, label: '최근 7일', value: '7일' }, { id: 2, label: '최근 30일', value: '30일' }, { id: 3, label: '최근 60일', value: '60일' }, { id: 4, label: '전체', value: '전체' }]
    const [bottomSheetValue, setButtomSheetValue] = useState<string>(bottomSheetDataList[0].value.toString());
    const [issuedTicketList, setIssuedTicketList] = useState<Array<IIssuedTicketPayment>>([]);
    const { memberId } = useParams();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(false);
    const [bottomSheetModalStatus, setBottomSheetModalStatus] = useState<boolean>(false);
    useEffect(() => {
        getIssuedTicketPaymentListByMemberIdAndPeriod(Number(memberId), bottomSheetValue)
            .then((res) => setIssuedTicketList(res.data))
            .catch((error) => console.error("error", error))
            .finally(() => setIsInitLoading(false))
    }, [bottomSheetValue])

    return <>
        {isInitLoading && <MyCheckInSkeleton />}
        {!isLogin()
            ? <>
                <TopBar title="결제 내역" rightIcon={false} />
                <NavigateLogin />
            </>
            : <>
                <TopBar title="결제 내역" rightIcon={false} />
                <div style={{ padding: '0 var(--space20) var(--space32)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '24px' }}>
                        <div className="sub-title/st3 base/black">
                            {issuedTicketList.length} 건
                        </div>
                        <BottomSheet
                            isOpen={bottomSheetModalStatus}
                            setIsOpen={setBottomSheetModalStatus}
                            dataList={bottomSheetDataList}
                            value={bottomSheetValue}
                            setValue={setButtomSheetValue}
                            isShowLabel />
                    </div>
                    <div>
                        {issuedTicketList.map((issuedTicket, idx) => <Fragment key={idx}>
                            <PaidHistoryCard issuedTicket={issuedTicket} />
                            {issuedTicketList.length - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space16)' }} />}
                        </Fragment>)}

                    </div>
                </div>
            </>}
    </>
}

export { PaidHistory }