import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TopBar } from "../../../widgets";
import { getIssuedYogaCouponById } from "../../../service/issuedYogaCoupon";
import { SolidBtn } from "../../../shared";

const IssuedYogaCouponDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [issuedYogaCouponData, setIssuedYogaCouponData] = useState<any | undefined>(undefined);

    useEffect(() => {
        requestIssuedYogaCouponData();
    }, [id])

    const requestIssuedYogaCouponData = () => {
        if (!id) return;

        getIssuedYogaCouponById(id)
            .then((res) => {
                setIssuedYogaCouponData(res.data);
            }).catch((error) => console.error('error', error))
    }

    return <>
        <TopBar title="쿠폰 상세" rightIcon={false} />
        <div style={{ backgroundColor: "#F2F2F2", minHeight: "100vh", padding: 20 }}>
            {issuedYogaCouponData && <>
                <div style={{ backgroundColor: "#FFF" }}>
                    <div style={{ borderBottom: "1px dotted #DDDDDD", padding: "32px 0", textAlign: "center" }}>
                        <div className="body/b2">
                            {issuedYogaCouponData.yogaCouponDto.name}
                        </div>
                        <div className="title/t4" style={{ margin: "8px 0 11px 0" }}>
                            {issuedYogaCouponData.yogaCouponDto.shortDescription}
                        </div>
                        <div className="body/b1 secondary/s700">
                            유효기간&nbsp; {`${issuedYogaCouponData.expireDate.slice(0, 4)}년 ${issuedYogaCouponData.expireDate.slice(5, 7)}월 ${issuedYogaCouponData.expireDate.slice(8, 10)}일까지`}
                        </div>
                    </div>
                </div>
                <SolidBtn type='solid' icon={false} state={issuedYogaCouponData.isUsed || issuedYogaCouponData.isDelete ? 'disabled' : 'default'} style={{ marginTop: '24px', padding: 'var(--space16) var(--space20)' }} onClick={() => !(issuedYogaCouponData.isUsed || issuedYogaCouponData.isDelete) && navigate('/ticket')}>
                    <div className="sub-title/st3">
                        {issuedYogaCouponData.isUsed ? '사용 완료' : issuedYogaCouponData.isDelete ? '기간 만료' : '사용하기'}
                    </div>
                </SolidBtn>
            </>}
            <div style={{ marginTop: "24px" }}>
                <div className="sub-title/st1" style={{ color: "var(--secondarys700)" }}>
                    사용 방법
                    <div className="body/b1" style={{ marginTop: "4px" }}>
                        <div>&#183; MY &gt; 정기권 구매 &gt; 결제 단계에서 ‘사용가능 쿠폰' 선택 후 적용</div>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "24px" }}>
                <div className="sub-title/st1" style={{ color: "var(--secondarys700)" }}>
                    유의사항
                    <div className="body/b1" style={{ marginTop: "4px" }}>
                        <div>&#183; 할인 쿠폰은 다른 쿠폰과 중복 사용이 불가능합니다.</div>
                        <div>&#183; 제휴 및 임직원 할인 사용 시 쿠폰 중복 적용이 불가능합니다.</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default IssuedYogaCouponDetail;