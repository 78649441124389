import { Carousel } from "antd";
import dayjs from "dayjs";
import { Fragment } from "react";

interface IProps {
    selectedDate: string
    onClick: Function
}

const DateSelectorCarousel = ({ selectedDate, onClick }: IProps) => {
    const getMonthDaysForToday = () => {
        let days = [];
        const daysListForSeven = [];
        for (let date = dayjs(); date.isBefore(dayjs().add(1, "M")) || date.isSame(dayjs().add(1, "M"), 'day'); date = date.add(1, 'day')) {
            const dayObject = {
                day: date.format('ddd'),
                date: date.date(),
                dayjs: date.format("YYYY-MM-DD")
            };
            days.push(dayObject);
            if (days.length === 7) {
                daysListForSeven.push(days);
                days = [];
            }
        }
        if (days.length > 0) {
            while (days.length < 7) {
                days.push(undefined)
            }
            daysListForSeven.push(days);
        }
        return daysListForSeven;
    };

    const clickDate = async (day: string) => {
        onClick(day)
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px", marginInline: '20px' }} >
            <Carousel dots={false} infinite={false}>
                {getMonthDaysForToday().map((days, idx: number) => <Fragment key={idx}>
                    <div style={{ display: 'flex' }}>
                        {days.map((day, idx) => <Fragment key={idx}>
                            <div
                                onClick={() => day && clickDate(day.dayjs)}
                                style={{
                                    backgroundColor: day && selectedDate === day.dayjs ? "var(--primaryp400)" : undefined,
                                    borderRadius: "999px",
                                    gap: "6px",
                                    cursor: "pointer",
                                    width: "16.6%",
                                    paddingBlock: "12px"
                                }}>
                                {day && <>
                                    <div className="sub-title/st1" style={{ textAlign: "center" }}>
                                        {day.dayjs === dayjs().format("YYYY-MM-DD")
                                            ? <div>오늘</div>
                                            : <div className="secondary/s500">{day.day}</div>}
                                    </div>
                                    <div className="title/t1" style={{ textAlign: "center", marginTop: '8px' }}>
                                        {day.date}
                                    </div>
                                </>}
                            </div>
                        </Fragment>)}
                    </div>
                </Fragment>
                )}
            </Carousel>
        </div>
    </>)
}
export { DateSelectorCarousel }