import { Flex, Skeleton } from "antd";
import { ReactComponent as CircleCheck } from "../../../../assets/images/icon/circle-check.svg";
import { useMyContext } from "../../../../entities/context";

const TicketByCenterIdSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
        <div style={{ padding: '0 24px 160px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 37 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div style={{ width: '16px' }}>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>
            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>
            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>
            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>
            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 50, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div>
                        <CircleCheck width={20} height={20} stroke={"#DDDDDD"} fill={'white'} />
                    </div>
                </Flex>
            </div>
        </div>
    </div>)
}

export default TicketByCenterIdSkeleton;