import React from "react";
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"

interface IProps {
    sementic?: "default" | "primary" | "danger";
    type?: "solid" | "line";
    state?: "default" | "disabled";
    icon?: boolean;
    customIcon?: React.ReactNode;
    style?: React.CSSProperties;
    styles?: { container?: React.CSSProperties, children?: React.CSSProperties }
    children?: React.ReactNode;
    onClick?: Function;
}

const SolidBtn = ({ sementic, type, state, icon = true, customIcon, style, styles, children, onClick }: IProps) => {
    const isDisabled = state === 'disabled';
    const getStyle = (): React.CSSProperties => {

        if (isDisabled) {
            return type === 'line'
                ? { backgroundColor: '#FFFFFF', color: '#AEAEAE', cursor: 'auto', border: '1px solid #E9E9E9' }
                : { backgroundColor: '#E9E9E9', color: '#AEAEAE', cursor: 'auto' };
        }

        if (type === 'line') {
            const lineColors = {
                default: { color: '#000000', borderColor: '#000000' },
                primary: { color: '#FFF743', borderColor: '#FFF743' },
                danger: { color: '#FF6666', borderColor: '#FF6666' }
            };

            const { color, borderColor } = lineColors[sementic || 'default'];
            return { backgroundColor: '#FFFFFF', color, border: `1px solid ${borderColor}` };
        }

        const sementicColors = {
            primary: { backgroundColor: '#FFF743', color: '#000000' },
            danger: { backgroundColor: '#FF6666', color: '#FFFFFF' },
            default: { backgroundColor: 'black', color: '#FFFFFF' }
        };

        return sementicColors[sementic || 'default'];
    };

    const getIconColor = (): string => {
        if (state === 'disabled') {
            return '#AEAEAE';
        }
        const colorMapping = {
            danger: {
                line: '#FF3333',
                solid: '#FFFFFF',
            },
            primary: {
                line: '#FFF743',
                solid: '#000000',
            },
            default: {
                line: '#555555',
                solid: '#FFFFFF',
            },
        };

        const sementicType = colorMapping[sementic || 'default'];
        return sementicType[type || 'solid'];
    };
    return <>
        <div
            className="sub-title/st3 base/black"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--space8)',
                // border: '1px solid var(--baseblack)',
                borderRadius: 'var(--radius4)',
                padding: 'var(--space12) var(--space20)',
                cursor: 'pointer',
                ...getStyle(),
                ...style
            }}
            onClick={() => !isDisabled && onClick?.()}
        >
            <div>{children}</div>
            {icon && !customIcon && <ArrowRight width={16} height={16} stroke={getIconColor()} />}
            {customIcon && customIcon}
        </div>
    </>
}
export { SolidBtn }