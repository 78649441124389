import { AxiosResponse } from 'axios';
import api from './Api'

export const getMemberForReservationByToken = (yogaClassId: number): Promise<AxiosResponse<IMobileIssuedTicketGetResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/member/reservation/${yogaClassId}`);
}
export const updateFcmToken = (tokens: IUpdateFcmToken) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/mobile/fcm/tokens`, tokens);
}

export const getMemberDataByToken = (): Promise<AxiosResponse<IMember, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/member/token`);
}

export const getMemberCouponPointTicket = (): Promise<AxiosResponse<IMemberPointCouponForResign, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/member/resign/token`);
}