import { Modal, message } from "antd"
import React, { useEffect, useState } from "react"
import { BottomBtn } from "../../../../../shared"
import { LectureInfoRow } from "../../lectureInfoRow";
import { lectureDatePeriodFormat } from "../../../../../utils";
import dayjs from "dayjs";
import { cancelReservation } from "../../../../../service";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../modules";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { getLectureListByCenterThunk } from "../../../../../modules/lectureByCenter";

interface IProps {
    yogaLecture: IYogaLectureByIdAndMemberId
    memberId: number
    requestYogaLectureById: Function
}
const CancelReservationWithModalInLectureDetail = ({ yogaLecture, memberId, requestYogaLectureById }: IProps) => {
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const navigate = useNavigate();
    const { lectureByCenterData } = useSelector((state: RootState) => state.lectureByCenter.lectureByCenterList);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isRequestCancel, setIsRequestCancel] = useState<boolean>(false)
    const [isCancelDisableModalOpen, setIsCancelDisableModalOpen] = useState<boolean>(false);
    const [isCancelCompleteModalOpen, setIsCancelCompleteModalOpen] = useState<boolean>(false);

    message.config({ top: 200 })

    const clickCancelReservation = async () => {
        if (!yogaLecture) return;

        if (dayjs().isAfter(dayjs(yogaLecture.startDateTime).add(-3, 'h'))) {
            setIsOpen(false);
            setIsCancelDisableModalOpen(true);
            return;
        }

        setIsOpen(false);
        try {
            setIsRequestCancel(true)
            const res = await cancelReservation(yogaLecture.id, memberId)
            if (["중이라 취소 할 수 없습니다.", "3시간 이전에만 취소가 가능합니다."].some(checkString => res.data.includes(checkString))) {
                message.error(res.data)
            } else {
                setIsCancelCompleteModalOpen(true);
                if (lectureByCenterData?.centerId) {
                    await dispatch(getLectureListByCenterThunk(lectureByCenterData?.centerId, lectureByCenterData?.selectedDate ? lectureByCenterData?.selectedDate : dayjs().format("YYYY-MM-DD"), dayjs().add(1, "M").format("YYYY-MM-DD")));
                }
            }
        } catch (error) {
            console.error('error', error)
        } finally { setIsRequestCancel(false) }
    }

    const onClickSuccessCancelReservation = () => {
        setIsCancelCompleteModalOpen(false);
    }
    useEffect(() => {
        if (!isCancelCompleteModalOpen)
            requestYogaLectureById()
    }, [isCancelCompleteModalOpen])
    return <>
        <BottomBtn
            onClick={() => setIsOpen(isOpen ? false : true)}
            text={isRequestCancel ? "취소 중" : "취소하기"} />

        <Modal
            centered
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={null}>
            <div>
                <div className="sub-title/st3" style={{ paddingBlock: 6 }}>예약을 취소할까요?</div>
                <div className="body/b1 secondary/s800">취소하려는 예약 내역을 다시 확인해 보세요.</div>
            </div>

            <div style={{ marginTop: 16, padding: 12, backgroundColor: 'var(--secondarys100)', color: 'var(--secondarys700)' }}>
                <LectureInfoRow label="시간" value={lectureDatePeriodFormat(yogaLecture.startDateTime, yogaLecture.endDateTime)} />
                <LectureInfoRow label="장소" value={`${yogaLecture.yogaCenterName} ${yogaLecture.room}`} />
                <LectureInfoRow label="코치" value={`${yogaLecture.teacherName} 코치`} />
            </div>

            <div
                className="sub-title/st3"
                style={{ border: '1px solid var(--secondarys500)', borderRadius: 'var(--radius4)', color: 'var(--secondarys500)', padding: '12px', textAlign: 'center', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => clickCancelReservation()}>예약 취소하기</div>
        </Modal>

        <Modal centered open={isCancelDisableModalOpen} onCancel={() => setIsCancelDisableModalOpen(false)} footer={[]}>
            <div>
                <div className="sub-title/st3" style={{ paddingBlock: 6 }}>예약을 취소할 수 없습니다</div>
                <div className="body/b1 secondary/s800">수업 시작 3시간 이전에만 취소가 가능합니다.</div>
            </div>
            <div className="sub-title/st3"
                style={{ backgroundColor: 'white', border: '1px solid #555', color: 'black', padding: '15px', borderRadius: '5px', textAlign: 'center', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => setIsCancelDisableModalOpen(false)}>돌아가기</div>
        </Modal>

        <Modal centered open={isCancelCompleteModalOpen} onCancel={() => setIsCancelCompleteModalOpen(false)} footer={[]}>
            <div>
                <div className="sub-title/st3" style={{ paddingBlock: 6 }}>예약 취소 완료</div>
                <div className="body/b1 secondary/s800">취소가 완료되었습니다.</div>
            </div>

            <div style={{ marginTop: 16, padding: 12, backgroundColor: 'var(--secondarys100)', color: 'var(--secondarys700)' }}>
                <LectureInfoRow label="시간" value={lectureDatePeriodFormat(yogaLecture.startDateTime, yogaLecture.endDateTime)} />
                <LectureInfoRow label="장소" value={`${yogaLecture.yogaCenterName} ${yogaLecture.room}`} />
                <LectureInfoRow label="코치" value={`${yogaLecture.teacherName} 코치`} />
            </div>

            <div style={{ backgroundColor: 'black', color: 'white', padding: '15px', borderRadius: '5px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => onClickSuccessCancelReservation()}>닫기</div>
        </Modal>
    </>
}

export { CancelReservationWithModalInLectureDetail }