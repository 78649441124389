import { useNavigate, useSearchParams } from "react-router-dom";
import { getKakaoUser } from "../../../service";
import { useEffect, useState } from "react";
import { getUrl, removeUrl } from "../../../service";
import PolicyDataTransfer from "../../../components/modal/policyDataTransfer";
import { getPolicyDataTransferByToken } from "../../../service";

const KakaoLoginCallback = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [policyModalStatus, setPolicyModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (!code) {
            navigate('/login');
            return;
        }
        requestGetUser(code);
    }, [code]);

    const requestGetUser = async (code: string) => {
        try {
            await getKakaoUser(code)
            const beforeUrl = getUrl();
            removeUrl();

            getPolicyDataTransferByToken()
                .then(res => {
                    if (!res.data.isPersonInfoPolicyAgree || !res.data.isServiceUseTermAgree) { setPolicyModalStatus(true) } else { beforeUrl === '/my/barcode' ? navigate(beforeUrl ? beforeUrl + '/' + res.data.memberId : '/', { replace: true }) : navigate(beforeUrl ? beforeUrl : '/', { replace: true }) }
                })
                .catch(error => console.error("error", error))

        } catch (error) {
            console.error('error', error);
            alert("다른 소셜연동 계정이 존재합니다. 다른 소셜로 로그인해주세요.");
            navigate('/login')
        }

    }
    return <>
        <PolicyDataTransfer propsModalStatus={policyModalStatus} />
    </>
}
export default KakaoLoginCallback;