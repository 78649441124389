import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyPaidDetailSkeleton from "../../../../components/skeleton/my/myPaidDetailSkeleton";
import { TopBar } from "../../../../widgets";
import { DividerThin, TicketReceipt } from "../../../../shared";
import { getIssuedTicketPaymentById } from "../../../../service";
import { converteYogaCenter, formatPhoneNumber, usableDateFormat } from "../../../../utils";


const PaidHistoryDetail = () => {
    const { id } = useParams();
    const [issuedTicketData, setIssuedTicketData] = useState<IIssuedTicketPaymentForDetailHistory>();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!id) return;
        getIssuedTicketPaymentById(id)
            .then((res) => setIssuedTicketData(res.data))
            .catch((error) => console.error('error', error))
            .finally(() => setIsInitLoading(false))
    }, [])
    const discountedPriceValue = (discount: number, discountMethod: string, price: number) => {
        if (discountMethod === '비율') {
            return (price * discount).toLocaleString()
        } else if (discountMethod === '금액') {
            return (price - discount).toLocaleString();
        } else {
            return 0;
        }
    }

    return <>
        {isInitLoading && <MyPaidDetailSkeleton />}
        <TopBar title="결제 내역 상세" rightIcon={false} />
        {issuedTicketData && <>
            <div style={{ padding: '24px 20px 40px' }}>
                <TicketReceipt ticket={issuedTicketData} >

                    <div className="title/t1">회원 정보</div>
                    <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">이름</div>
                            <div className="base/black">{issuedTicketData.memberName}</div>
                        </div>
                        <DividerThin />

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">연락처</div>
                            <div className="base/black">{formatPhoneNumber(issuedTicketData.mobile)}</div>
                        </div>
                        <DividerThin />
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">이메일</div>
                            <div className="base/black">{issuedTicketData.email}</div>
                        </div>
                    </div>

                    <div className="title/t1" style={{ marginTop: 'var(--space40)' }}>정기권 정보</div>
                    <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">상담 지점</div>
                            <div className="base/black">{converteYogaCenter(issuedTicketData.yogaCenterEnumName ?? '')}</div>
                        </div>
                        <DividerThin />

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">사용 가능 지점</div>
                            <div className="base/black">{issuedTicketData.yogaCenterName}</div>
                        </div>
                        <DividerThin />

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">수강권명</div>
                            <div className="base/black">{issuedTicketData.ticketName}</div>
                        </div>
                        <DividerThin />
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">사용기한</div>
                            <div className="base/black">{usableDateFormat(issuedTicketData.startDate) + " - " + usableDateFormat(issuedTicketData.expireDate)}</div>
                        </div>
                    </div>

                    <div className="title/t1" style={{ marginTop: 'var(--space40)' }}>결제 정보</div>
                    {!issuedTicketData.totalAmount
                        ? <div style={{ fontSize: '13px', color: "#777", textAlign: "center", marginTop: 'var(--space20)' }}>인앱 결제 내역이 없습니다.</div>
                        : <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">수강권 금액</div>
                                <div>{issuedTicketData.price?.toLocaleString()}원</div>
                            </div>
                            <DividerThin />

                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">수강권명</div>
                                <div className="base/black">{issuedTicketData.ticketName}</div>
                            </div>
                            <DividerThin />
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">할인</div>
                                <div className="base/black">-{discountedPriceValue(issuedTicketData.discount, issuedTicketData.discountMethod, issuedTicketData.price)}원</div>
                            </div>
                            <DividerThin />
                            {issuedTicketData.pointValue <= 0
                                ? <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                        <div className="secondary/s800">포인트</div>
                                        <div className="base/black">{issuedTicketData.pointValue}원</div>
                                    </div>
                                    <DividerThin />
                                </>
                                : <>
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                                    <div className="secondary/s800">포인트 적립</div>
                                                    <div className="base/black">{issuedTicketData.pointValue}원</div>
                                                </div>
                                                <DividerThin /> */}
                                </>}
                            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                            <div>
                                                <div className="secondary/s800">쿠폰</div>
                                                <div className="body/b1 secondary/s500">[요가] ALL PASS 20% 쿠폰이름</div>
                                            </div>
                                            <div className="base/black">-{0}원</div>
                                        </div>
                                        <DividerThin /> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space20)' }}>
                                <div className="sub-title/st2 secondary/s800">총 결제 금액</div>
                                <div className="sub-title/st3 base/black">{issuedTicketData.totalAmount.toLocaleString()}원</div>
                            </div>
                            <div className="body/b1 secondary/s500">회당 금액 {(issuedTicketData.totalAmount / issuedTicketData.totalUsableCnt).toLocaleString()}원</div>
                        </div>}

                </TicketReceipt>
            </div>
        </>}
    </>
}

export { PaidHistoryDetail };