import { useEffect, useState } from "react";
import MyUserSkeleton from "../../../components/skeleton/my/myUserSkeleton";
import { useNavigate } from "react-router-dom";
import { Divider, Flex } from "antd";
import { formatPhoneNumber, isLogin } from "../../../utils";
import { getMemberDataByToken, removeUser, logoutUser } from "../../../service";
import { TopBar } from "../../../widgets";
import { ReceiveMarketingSwitch, BasicInfoRow, ResignInfo } from "../../../shared";
import { ReactComponent as Info } from "../../../assets/images/icon/info.svg"
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"


const Setting = () => {
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [memberData, setMemberData] = useState<IMember | undefined>(undefined);
    const [resignInfoModalStatus, setResignInfoModalStatus] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogin()) {
            navigate("/login")
            return;
        }

        getMemberDataByToken()
            .then((res) => {
                setMemberData(res.data);
            })
            .catch(() => removeUser())
            .finally(() => setIsInitLoading(false));
    }, [])

    const logoutButtonClick = async () => {
        logoutUser()
            .then((res) => { })
            .catch((error) => console.error("error: ", error))
            .finally(() => {
                removeUser();
                navigate("/login");
            });
    }

    return <>
        {(isInitLoading || !memberData) && <MyUserSkeleton />}
        {memberData && <>
            <TopBar title="설정" rightIcon={false} />
            <div style={{ marginTop: 24, padding: '0 20px' }}>
                <div className="title/t1">회원 정보</div>
                <div style={{ marginTop: 20 }}>
                    <BasicInfoRow title="이름" content={memberData.name} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow title="연락처" content={formatPhoneNumber(memberData.mobile)} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow title="이메일" content={memberData.email} />
                </div>
            </div>

            <div style={{ marginTop: 40, padding: '0 20px' }}>
                <div className="title/t1">약관 및 개인정보 처리 동의</div>
                <div style={{ marginTop: 20 }}>
                    <BasicInfoRow onClick={() => navigate('/serviceUseTerm')}
                        title={<Flex style={{ alignItems: 'center' }} gap={4}>
                            <div className="body/b3">서비스 이용약관</div>
                        </Flex>} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow onClick={() => navigate('/personInfoPolicy')} title={'개인정보 처리방침'} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow onClick={() => navigate('/pointUsePolicy')} title={'포인트 이용기준'} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow onClick={() => navigate('/refundPolicy')} title={'정기권 환불 정책'} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <ReceiveMarketingSwitch memberId={memberData.id} title="마케팅 정보 수신 동의" setIsInitLoading={setIsInitLoading} />
                </div>
            </div>

            <Divider style={{ borderBlockStart: '6px solid var(--secondarys100)', margin: '20px 0' }} />
            <div style={{ padding: '0 20px 40px 20px' }}>
                <BasicInfoRow onClick={logoutButtonClick} title={'로그아웃'} />
                <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                <Flex
                    className="body/b3"
                    style={{ padding: '14px 0 14px 12px', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ color: 'var(--secondarys800)' }}>
                        <Flex style={{ alignItems: 'center' }} gap={4}>
                            <div className="body/b3">탈퇴하기</div>
                            <Info width={16} height={16} fill="#AEAEAE" style={{ cursor: "pointer" }} onClick={() => setResignInfoModalStatus(true)} />
                        </Flex>
                    </div>
                    <ArrowRight width={16} height={16} stroke="#737373" style={{ cursor: "pointer" }} onClick={() => navigate('/resign')} />
                </Flex>
            </div>
            <ResignInfo resignInfoModalStatus={resignInfoModalStatus} setResignInfoModalStatus={setResignInfoModalStatus} />
        </>}
    </>
}

export { Setting }