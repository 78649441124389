import { AxiosResponse } from 'axios';
import api from './Api'

export const getIssuedTicketPaymentListByMemberIdAndPeriod = (memberId: number, period: string): Promise<AxiosResponse<Array<IIssuedTicketPayment>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/issued-ticket/member/${memberId}?period=${period}`)
}
export const getIssuedTicketPaymentById = (id: string): Promise<AxiosResponse<IIssuedTicketPaymentForDetailHistory, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/issued-ticket/payment/${id}`)
}
export const getIssuedTicketDetailById = (id: string): Promise<AxiosResponse<IMobileIssuedTicketDetail, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/issued-ticket/${id}`)
}
export const createIssuedTicketEventCouponWithoutMoney = (params: { ticketId: string, eventCouponId?: number, usedPoint: number, issuedYogaCouponId?: number }): Promise<AxiosResponse<Boolean, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/issued-ticket/eventcoupon/without-money`, params)
}