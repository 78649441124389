import { useEffect, useState } from "react";
import { getYogaCenterDetail } from "../../../service";
import { useParams } from "react-router-dom";
import CenterDetailSkeleton from "../../../components/skeleton/center/centerDetailSkeleton";
import { TopBar } from "../../../widgets";
import { BottomBtn, Carousel } from "../../../shared";
import { CustomAnchor, YogaCenterInfo } from "../../../features";

const YogaCenterDetail = () => {
    const { id } = useParams();
    const [yogaCenterDetail, setYogaCenterDetail] = useState<IYogaCenterDetail | undefined>(undefined);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        requestYogaCenterDetail()
    }, [])

    const requestYogaCenterDetail = async () => {
        if (!id) return;
        try {
            const res = await getYogaCenterDetail(id);
            const yogaCenterDetailData = res.data;
            setYogaCenterDetail({ ...yogaCenterDetailData, centerImageList: yogaCenterDetailData.centerImageList.sort((a, b) => a.idx - b.idx) })
            const img = new Image();
            img.src = yogaCenterDetailData.centerImageList[0].url;
            img.onload = () => {
                setImageLoaded(true);
            }
        } catch (error) {
            console.error("Error: ", error);
            setIsInitLoading(false);
        }
    }

    useEffect(() => { if (imageLoaded) setIsInitLoading(false) }, [imageLoaded])

    return <>
        {isInitLoading && <CenterDetailSkeleton />}
        <TopBar title={yogaCenterDetail ? "파지티브호텔 요가 " + yogaCenterDetail.name : ''} rightIcon={false} />
        {yogaCenterDetail && <>
            <Carousel carouselList={yogaCenterDetail.centerImageList.filter(q => q.type === "main").map(q => ({ imageUrl: q.url, linkUrl: '#' }))} imgSize={{ x: 1, y: 1 }} />
            <YogaCenterInfo yogaCenterDetail={yogaCenterDetail} style={{ padding: '24px var(--space20)' }} />
            <CustomAnchor yogaCenterDetail={yogaCenterDetail} style={{ marginBlock: 'var(--space8) var(--space40)' }} />
        </>}
        <BottomBtn to={yogaCenterDetail ? `/reservation?id=${yogaCenterDetail.id}` : ''} text="수업 둘러보기" />
    </>
}
export { YogaCenterDetail }