import { useEffect, useState } from "react";
import MySkeleton from "../../components/skeleton/my/mySkeleton";
import { MyBeforeLogin } from "./ui/myBeforeLogin";
import { TopBar } from "../../widgets";
import { IssuedTicketAvailableList, ReservatedProgramList } from "../../features";
import { GnbFooter, MemberNameAndTitle, MemberBenefitInfo, BasicInfoRow } from "../../shared";
import { getMemberDataByToken, removeUser, getPremiumMembership, getMyTicketByToken, myReservationByToken, getDosanProgramMember } from "../../service";
import { isLogin, moneyStringFormatByNumber, isPlatformTeamMember } from "../../utils";
import dayjs from "dayjs";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";

const My = () => {
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [memberData, setMemberData] = useState<IMember | undefined>(undefined);
    const [premiumMembership, setPremiumMembership] = useState<IPremiumMembership | undefined>(undefined);
    const [grade, setGrade] = useState<string>('-');
    const [dosanProgramMember, setDosanProgramMember] = useState<IDosanProgramMember | undefined>(undefined);
    const [myTicketList, setMyTicketList] = useState<Array<IMyTicket>>([]);
    const [myReservationList, setMyReservationList] = useState<Array<IMyReservation>>([]);

    const requestMyTicketByToken = async () => {
        try {
            const res = await getMyTicketByToken()
            setMyTicketList(res.data.filter((myTicket: IMyTicket) => !myTicket.isDelete));
            setIsInitLoading(false);

            const isSuite = res.data.filter((ticket: IMyTicket) => ticket.yogaCenterName === "ALL PASS" && dayjs(ticket.period.slice(12).replaceAll(".", "-")).isAfter(dayjs())).length > 0;
            if (isSuite) { return setGrade("SUITE"); }

            const isDelux = res.data.filter((ticket: IMyTicket) => ticket.yogaCenterName !== "ALL PASS" && dayjs(ticket.period.slice(12).replaceAll(".", "-")).isAfter(dayjs())).length > 0;
            if (isDelux) { return setGrade('DELUXE'); }
            return setGrade("STANDARD");

        } catch (error) {
            console.error('error', error);
            setIsInitLoading(false);
        }
    }
    const requestMyReservationByToken = async () => {
        try {
            const res = await myReservationByToken();
            setMyReservationList(res.data.filter((myReservation: IMyReservation) => (myReservation.status === 'RESERVATION') || (myReservation.status === 'CHECK_IN')));
        } catch (error) {
            console.error('error', error);
        }
    }
    useEffect(() => {
        const existToken = isLogin();

        if (existToken) {
            getMemberDataByToken()
                .then((res) => {
                    setMemberData(res.data);
                    requestMyTicketByToken();
                    requestMyReservationByToken();
                })
                .catch((error) => { console.error('error', error); removeUser() })
            getDosanProgramMember()
                .then((res) => {
                    if (res.data) {
                        setDosanProgramMember(res.data);
                    }
                })
                .catch((error) => { console.error('error', error); })
            getPremiumMembership()
                .then((res) => {
                    if (res.data) {
                        setPremiumMembership(res.data)
                    }
                })
                .catch((error) => { console.error('error', error); })
        } else {
            setIsInitLoading(false);
        }
    }, [])

    useEffect(() => {
        const existToken = isLogin();

        if (existToken) {
            getMemberDataByToken()
                .then((res) => setMemberData(res.data))
                .catch(() => removeUser())
        } else {
            setIsInitLoading(false);
        }
    }, [])

    return <>
        {isInitLoading && <MySkeleton />}
        <div style={{ marginBottom: '52px' }}>
            {!memberData
                ? <>
                    <TopBar title="MY" leftIcon={false} rightIcon={false} />
                    <MyBeforeLogin />
                </>
                : <>
                    <TopBar title="MY" leftIcon={false} rightType='setting' />
                    <div style={{ marginInline: 'var(--space20)', marginTop: 43 }}>
                        <MemberNameAndTitle
                            name={memberData.name}
                            title={memberData.isManager ? "매니저님" : memberData.isTeacher ? '코치님' : '회원님'} />
                        <div style={{ display: 'flex', gap: 'var(--space16)', marginBlock: '24px' }}>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="멤버십" value={premiumMembership ? premiumMembership.membershipTitle : grade} to={`/member-ship?grade=${grade === "STANDARD" ? 0 : grade === "DELUXE" ? 1 : 2}`} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="포인트 · 쿠폰" value={moneyStringFormatByNumber(memberData.totalPoint) + "p"} to="/my/reward" />
                            </div>
                        </div>
                    </div>

                    <IssuedTicketAvailableList myTicketList={myTicketList} />
                    <ReservatedProgramList dosanProgramMember={dosanProgramMember} requestMyReservationByToken={requestMyReservationByToken} requestMyTicketByToken={requestMyTicketByToken} myReservationList={myReservationList} memberData={memberData} />

                    <div style={{ padding: 'var(--space16) var(--space20) 0' }}>
                        {memberData.isTeacher && <>
                            <BasicInfoRow title={memberData.isManager ? "모든 수업 확인" : "내 수업 확인"} onClick={() => navigate(`/mylecture/teacher/${memberData.teacherId}`)} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        </>}
                        <BasicInfoRow title="수련 내역" onClick={() => navigate('/my/training-history')} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"결제 내역"} onClick={() => navigate("/my/paid-history/" + memberData.id)} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"정기권 구매"} onClick={() => navigate("/ticket")} />
                        {memberData.isManager && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow title={"이벤트"} onClick={() => navigate("/event")} />
                        </>}
                        {isPlatformTeamMember(memberData.mobile) && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow
                                title={window.location.origin === "https://dev.positivehotelyoga.com" ? "운영용 페이지로 이동" : "개발용 페이지로 이동"}
                                onClick={() => window.location.href = window.location.origin === "https://dev.positivehotelyoga.com" ? "https://positivehotelyoga.com" : "https://dev.positivehotelyoga.com"} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow title={"도산 초대장"} onClick={() => navigate("/invitation/dosan/program")} />
                        </>}
                    </div>
                </>}
        </div>
        <GnbFooter activeButton='my' />
    </>
}

export { My }