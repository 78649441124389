import { Flex, Skeleton } from "antd";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icon/arrow-right.svg"
import { useMyContext } from "../../../../entities/context";

const TicketSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', maxWidth: '480px' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 92, marginTop: 37 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div style={{ width: '16px' }}>
                        <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </div>
                </Flex>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 92, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div style={{ width: '16px' }}>
                        <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </div>
                </Flex>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 92, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div style={{ width: '16px' }}>
                        <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </div>
                </Flex>
            </div>

            <div style={{ border: '1px solid #D9D9D9', padding: 16, borderRadius: '4px', height: 92, marginTop: 20 }}>
                <Flex gap={16} style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 80 }} />
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 4 }} />
                    </div>
                    <div style={{ width: '16px' }}>
                        <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </div>
                </Flex>
            </div>
        </div>
    </div>)
}

export default TicketSkeleton;