import { Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";


const MyEventSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<>
        <div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), padding: '0 24px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '45px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 200 }} />
            </div>

            <div style={{ width: '100%', marginTop: '16px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                <div style={{ marginTop: 4, width: '160px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>

            <div style={{ width: '100%', marginTop: '32px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 200 }} />
            </div>

            <div style={{ width: '100%', marginTop: '16px' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                <div style={{ marginTop: 4, width: '160px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
        </div>
    </>
    )
}

export default MyEventSkeleton;