import { AxiosResponse } from 'axios';
import api from './Api'

interface IParam {
    partnerOrderId: string,
    partnerUserId: string,
    itemName: string,
    quantity: number,
    totalAmount: number,
    taxFreeAmount: number,
    point: number
    ticketId: number;
}

export const requestKakaoPay = (param: IParam): Promise<AxiosResponse<IKakaoPayReadyResponse, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/kakaoPay`, param);
}

export const getKakaoPaySuccessInfo = (token: string): Promise<AxiosResponse<any, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/kakaoPaySuccess/${token}`);
}