import { combineReducers } from 'redux';
import member from './member'
import lectureByCenter from './lectureByCenter'
import initYogaCenterSelector from './initYogaCenterSelector'

const rootReducer = combineReducers({
    member,
    lectureByCenter,
    initYogaCenterSelector
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;