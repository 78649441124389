import { useNavigate } from "react-router-dom";


interface IProps {
    issuedTicket: IIssuedTicketPayment
    style?: React.CSSProperties
}
const PaidHistoryCard = ({ issuedTicket, style }: IProps) => {
    const navigate = useNavigate();
    return <div key={issuedTicket.id} style={{ cursor: "pointer", ...style }} onClick={() => navigate(`${issuedTicket.id}`)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="body/b3 base/black">
                {issuedTicket.yogaCenterName + " " + issuedTicket.ticketName}
            </div>
            <div className="sub-title/st3 base/black">
                {issuedTicket.totalAmount ? issuedTicket.totalAmount.toLocaleString() + "원" : "앱 외 결제"}
            </div>
        </div>
        <div className="body/b1 secondary/s500" style={{ marginTop: 'var(--space12)' }}>
            {issuedTicket.paidDateTime ? issuedTicket.paidDateTime.slice(0, 19).replaceAll("-", ".").replaceAll("T", " ") : issuedTicket.createdDate.slice(0, 19).replaceAll("-", ".").replaceAll("T", " ")}
        </div>
    </div>
}
export { PaidHistoryCard }