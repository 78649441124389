import { createReducer } from 'typesafe-actions';
import { MemberState, MemberAction } from './types';
import {
    GET_MEMBER,
    GET_MEMBER_SUCCESS,
    GET_MEMBER_ERROR
} from './actions';


const initialState: MemberState = {
    member: {
        memberLoading: false,
        memberError: null,
        memberData: null
    }
};

const member = createReducer<MemberState, MemberAction>(initialState, {
    [GET_MEMBER]: state => ({
        ...state,
        member: {
            memberLoading: true,
            memberError: null,
            memberData: null
        }
    }),
    [GET_MEMBER_SUCCESS]: (state, action) => ({
        ...state,
        member: {
            memberLoading: false,
            memberError: null,
            memberData: action.payload
        }
    }),
    [GET_MEMBER_ERROR]: (state, action) => ({
        ...state,
        member: {
            memberLoading: false,
            memberError: action.payload,
            memberData: null
        }
    }),
});

export default member;