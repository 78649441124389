import axios from "axios";
import { getLocalAccessToken, removeUser, setUser } from ".";
import { setUrl } from "./Url";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

instance.interceptors.request.use((config: any) => {
    const token = getLocalAccessToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (res) => {
        const accessToken = res.headers["authorization"];
        if (accessToken) {
            setUser(accessToken)
        }
        return res;
    },
    async (err) => {
        if (!err.response) {
            console.error("response is not found.  err: ", err);
        } else { console.error("err: ", err); }
        if (err.response.status === 401) {
            removeUser();
            setUrl(window.location.pathname)
            Promise.reject(err).finally(() => { window.location.href = "/login"; });
        }
        return Promise.reject(err);
    }
);
export default instance;