import { Modal } from "antd"
import { ReactComponent as Close } from "../../assets/images/icon/close.svg"
import { useEffect, useState } from "react";
import { getNoticePopupForPopup } from "../../service";

const NoticePopupModal = () => {
    const [noticePopupModalStatus, setNoticePopupModalStatus] = useState<boolean>(false);
    const [noticePopupContent, setNoticePopupContent] = useState<INoticePopup>();
    const [localStorageKey, splitKey] = ['notViewedUntil', ','];

    useEffect(() => {
        getNoticePopupForPopup()
            .then((res) => {
                setNoticePopupContent(res.data);
                const today = getToday();
                const checkValue = localStorage.getItem(localStorageKey)?.split(splitKey)
                if (checkValue?.[0] === res.data.id.toString()) {
                    const storedNotViewedUntil = parseInt(checkValue?.[1] ?? '0', 10);
                    if (!storedNotViewedUntil || today >= storedNotViewedUntil) {
                        setNoticePopupContent(res.data);
                        setNoticePopupModalStatus(res.data.isPopup)
                    }
                } else {
                    setNoticePopupContent(res.data);
                    setNoticePopupModalStatus(res.data.isPopup)
                }
            })
    }, [])

    const handleNotVeiwClick = (id: number, day: number) => {
        const today = getToday();
        const tomorrow = today + day * 24 * 60 * 60 * 1000;
        localStorage.setItem(localStorageKey, (id + splitKey + tomorrow));
    };

    const getToday = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today.getTime();
    };

    return (<>
        <Modal open={noticePopupModalStatus}
            centered
            footer={null}
            onCancel={() => setNoticePopupModalStatus(false)}
            closeIcon={<Close width={24} height={24} stroke="var(--secondarys800)" />}
            title={<div className="sub-title/st3 base/black" style={{ textAlign: 'center' }}>공지사항</div>}
            styles={{ header: { marginBottom: 16 } }}>

            <div className="sub-title/st1 base/black" style={{ whiteSpace: "pre-wrap" }}>
                {noticePopupContent?.title}
            </div>
            <div className="body/b1 secondary/s800"
                style={{ overflow: "scroll", height: "300px", marginBlock: '10px 16px', whiteSpace: "pre-wrap" }}>
                {noticePopupContent?.content}
            </div>
            <div className="sub-title/st3 base/black"
                style={{ borderRadius: "var(--radius4)", border: "1px solid var(--baseblack)", padding: "var(--space16) var(--space20)", textAlign: "center", cursor: "pointer" }}
                onClick={() => { setNoticePopupModalStatus(false); handleNotVeiwClick(noticePopupContent?.id ?? 0, 1) }}>
                오늘 그만보기
            </div>
            <div className="btn/text-btn secondary/s600"
                style={{ textAlign: "center", cursor: "pointer", marginTop: "16px" }}
                onClick={() => { setNoticePopupModalStatus(false); handleNotVeiwClick(noticePopupContent?.id ?? 0, 7) }}>
                7일간 보지않기
            </div>
        </Modal>
    </>)
}

export { NoticePopupModal }