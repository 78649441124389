import { Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";

const CenterDetailSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), height: '300vh' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 375, marginTop: 13 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 18, marginTop: 24, width: 160 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 29, marginTop: 8 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 96, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 154, marginTop: 20 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 43, marginTop: 20 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 96, width: 160 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 288, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 40, width: 160 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 16, width: 44 }} />
        </div>
    </div>)
}

export default CenterDetailSkeleton;