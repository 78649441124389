import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getKakaoUserByAccessToken } from "../../../../service";
import { getUrl, removeUrl } from "../../../../service";
import { updateFcmToken } from "../../../../service";
import { isLogin } from "../../../../utils";
import PolicyDataTransfer from "../../../../components/modal/policyDataTransfer";
import { getPolicyDataTransferByToken } from "../../../../service";

const KakaoLoginMobileAppCallback = () => {
    const navigate = useNavigate();
    const KAKAO_AUTH_URL = `kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI}&response_type=code`;
    const location = useLocation();
    const sch = location.search.replace(/&/g, "%26").replace(/\+/g, "%2B");
    const params = new URLSearchParams(sch);
    const accessToken = params.get('accessToken');
    const [policyModalStatus, setPolicyModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken) {
            requestKakaoUserByAccessToken();
        }
    }, [accessToken])

    const requestKakaoUserByAccessToken = async () => {
        if (!accessToken) {
            window.location.href = "https://" + KAKAO_AUTH_URL;
            return;
        }
        try {
            await getKakaoUserByAccessToken(accessToken);
            if (window.native_yoga_bridge) {
                window.native_yoga_bridge.sendMessage(JSON.stringify({
                    functionName: 'getFCMToken',
                    data: {}
                }));
                window.getFCMToken = (fcmToken: string) => {
                    isLogin() && updateFcmToken(({ fcmToken: fcmToken }))
                }
            }
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
                window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
                    functionName: 'getFCMToken',
                    data: {}
                }))
                window.getFCMToken = (fcmToken: string) => {
                    isLogin() && updateFcmToken(({ fcmToken: fcmToken }))
                }
            }
            const beforeUrl = getUrl();
            removeUrl();
            getPolicyDataTransferByToken()
                .then(res => {
                    if (!res.data.isPersonInfoPolicyAgree || !res.data.isServiceUseTermAgree) { setPolicyModalStatus(true) } else { navigate(beforeUrl ? beforeUrl : '/', { replace: true }) }
                })
                .catch(error => console.error("error", error))
        } catch (error) {
            console.error('error', error);
            alert("다른 소셜연동 계정이 존재합니다. 다른 소셜로 로그인해주세요.");
            navigate('/login')
        }

    }

    return <>
        <PolicyDataTransfer propsModalStatus={policyModalStatus} />
    </>
}
export default KakaoLoginMobileAppCallback;