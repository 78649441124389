import { AxiosResponse } from 'axios';
import api from './Api'

export const getPolicyDataTransferByMemberId = (memberId: string): Promise<AxiosResponse<IPolicyDataTransfer, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/policy_data_transfer/${memberId}`);
}
export const getPolicyDataTransferByMobile = (mobile: string): Promise<AxiosResponse<IPolicyDataTransfer, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/policy_data_transfer/mobile/{mobile}?mobile=${mobile}`);
}
export const getPolicyDataTransferByToken = (): Promise<AxiosResponse<IPolicyDataTransfer, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/policy_data_transfer/token`);
}

export const createOrUpdatePolicyDataTransferAgree = (policyDataTransferRequest: IPolicyDataTransferRequest): Promise<AxiosResponse<any, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/policy_data_transfer/agree`, policyDataTransferRequest);
}
export const updatePolicyDataTransferAgree = (policyDataTransferRequest: IPolicyDataTransferPutRequest): Promise<AxiosResponse<any, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/policy_data_transfer/agree`, policyDataTransferRequest);
}