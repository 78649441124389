import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl, removeUrl } from "../../../service";

const KakaoPayCancel = () => {
    const navigate = useNavigate();
    const beforeUrl = getUrl();
    removeUrl();

    useEffect(() => {
        navigate(beforeUrl ? beforeUrl : '/', { replace: true });
    }, [])

    return <>KakaoPayCancel</>
}
export default KakaoPayCancel;