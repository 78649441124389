import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTicketListByYogaCenterId } from "../../../service";
import TicketByCenterIdSkeleton from "../../../components/skeleton/ticket/ticketByCenterIdSkeleton";
import { TopBar } from "../../../widgets";
import { BottomBtn, TicketSelectByYogaCemterCard } from "../../../shared";

const TicketList = () => {
    const { yogaCenterId } = useParams();
    const [selectedTicketId, setSelectedTicketId] = useState<number | undefined>(undefined);
    const [ticketByYogaCenter, setTicketByYogaCenter] = useState<IMobileTicketByYogaCenter>();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

    useEffect(() => {
        yogaCenterId && getTicketListByYogaCenterId(yogaCenterId)
            .then((res) => { setTicketByYogaCenter(res.data); })
            .catch((error) => { console.error('error', error); })
            .finally(() => { setIsInitLoading(false); });
    }, [])

    return <>
        {isInitLoading && <TicketByCenterIdSkeleton />}
        <TopBar title={ticketByYogaCenter?.titleName} rightIcon={false} />
        <div style={{ padding: '24px var(--space20)' }}>
            {ticketByYogaCenter?.ticketListList.sort((a, b) => a.totalUsableCnt - b.totalUsableCnt).map(ticket => <TicketSelectByYogaCemterCard key={ticket.id} ticket={ticket} isSelect={selectedTicketId === ticket.id} onClick={() => setSelectedTicketId(ticket.id)} />)}
        </div>
        <BottomBtn to={selectedTicketId ? '/pay-info/' + selectedTicketId : ''} text="선택하기" />
    </>
}
export { TicketList }