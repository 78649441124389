import { useNavigate } from "react-router-dom";
import MainHeader from "../../../layout/MainLayout/header";
import './SolutionFormStyle.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
// import required modules
import { EffectCards } from 'swiper/modules';
import './style.css'
import { Flex } from "antd";
import { createSolutionInvitationDoasnSurvey } from "../../../service";
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"
import { ReactComponent as ArrowLeft } from "../../../assets/images/icon/arrow-left.svg"

const SolutionForm = () => {
    const navigate = useNavigate();

    const changeSlide = (e: any) => {
        if (e.activeIndex === 0 || e.activeIndex === 2) {
            const solution = e.activeIndex === 0 ? 'GOOD IN' : e.activeIndex === 2 ? 'BAD OUT' : '';
            requestCreateSolutionInvitationDoasnSurvey(solution);
        }
    }

    const requestCreateSolutionInvitationDoasnSurvey = (solution: string) => {
        createSolutionInvitationDoasnSurvey(solution)
            .then(res => {
                navigate('/invitation/dosan/program/4')
            }).catch(err => {
                console.error('err', err);
            })
    }

    return (<>
        <MainHeader
            title={<div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center', height: '18px' }}>
                <div style={{ width: '70px', height: '2px', backgroundColor: '#000000' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#000000' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#000000' }}></div>
            </div>}
            customNavigate={'/invitation/dosan/program/2'}
            hiddenRightIcon />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)' }}>
            <div style={{ position: 'relative' }}>
                <Swiper
                    effect={'cards'}
                    initialSlide={1}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    onSlideChange={changeSlide}
                    onSwiper={(swiper: any) => console.log(swiper)}
                >
                    <SwiperSlide></SwiperSlide>
                    <SwiperSlide virtualIndex={1}>
                        <div style={{ width: 320, height: 420, padding: '35px 10px 10px 10px', textAlign: "center" }}>
                            <div style={{ height: '430px', width: '247px', textAlign: 'center', borderRadius: '16px', margin: '0 35px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '430px' }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ fontSize: '28px', fontWeight: '700', lineHeight: '1.4em' }}>어떤 솔루션이<br />필요한가요?</div>
                                        <div style={{ marginTop: '40px', fontSize: '12px' }}>필요한 솔루션 스와이프</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide></SwiperSlide>
                </Swiper>
                <div style={{ display: 'flex', justifyContent: "space-between", width: '335px', marginTop: '32px' }}>
                    <Flex
                        onClick={() => requestCreateSolutionInvitationDoasnSurvey('BAD OUT')}
                        style={{ fontSize: '18px', color: 'black', cursor: 'pointer', alignItems: 'center' }}>
                        <ArrowLeft width={18} height={18} stroke="#000000" />
                        <div>BAD OUT</div>
                    </Flex>
                    <Flex
                        onClick={() => requestCreateSolutionInvitationDoasnSurvey('GOOD IN')}
                        style={{ fontSize: '18px', color: 'black', cursor: 'pointer', alignItems: 'center' }}>
                        <div>GOOD IN</div>
                        <ArrowRight width={18} height={18} stroke="#000000" />
                    </Flex>
                </div>

            </div>
        </div>
    </>)
}

export default SolutionForm;