import { Fragment, useEffect, useState } from "react";
import { NavigateLogin, TrainingHistoryCard, DividerThin, BottomSheet } from "../../../shared";
import { TopBar } from "../../../widgets";
import { isLogin } from "../../../utils";
import MyCheckInSkeleton from "../../../components/skeleton/my/myCheckInSkeleton";
import { getLectureInCheckInByPeriod } from "../../../service";

const TrainingHistory = () => {
    const bottomSheetDataList: IBottomSheet[] = [{ id: 1, label: '최근 7일', value: '7일' }, { id: 2, label: '최근 30일', value: '30일' }, { id: 3, label: '최근 60일', value: '60일' }, { id: 4, label: '전체', value: '전체' }]
    const [bottomSheetValue, setButtomSheetValue] = useState<string>(bottomSheetDataList[0].value.toString());
    const [myCheckInList, setMyCheckInList] = useState<Array<IMyReservationCheckIn>>([]);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(false);
    const [bottomSheetModalStatus, setBottomSheetModalStatus] = useState<boolean>(false);

    useEffect(() => {
        requestLectureInCheckInByPeriod(bottomSheetValue)
    }, [bottomSheetValue])
    const requestLectureInCheckInByPeriod = (period: string) => {
        getLectureInCheckInByPeriod(period)
            .then((res) => setMyCheckInList(res.data))
            .catch((error) => console.error('error', error))
            .finally(() => setIsInitLoading(false))
    }
    return <>
        {isInitLoading && <MyCheckInSkeleton />}
        {!isLogin()
            ? <>
                <TopBar title="수련 내역" rightIcon={false} />
                <NavigateLogin />
            </>
            : <>
                <TopBar title="수련 내역" rightIcon={false} />
                <div style={{ padding: '0 var(--space20) var(--space32)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '24px' }}>
                        <div className="sub-title/st3 base/black">
                            {myCheckInList.length} 건
                        </div>
                        <BottomSheet
                            isOpen={bottomSheetModalStatus}
                            setIsOpen={setBottomSheetModalStatus}
                            dataList={bottomSheetDataList}
                            value={bottomSheetValue}
                            setValue={setButtomSheetValue}
                            isShowLabel />
                    </div>
                    <div>
                        {myCheckInList.map((checkIn, idx) => <Fragment key={idx}>
                            <TrainingHistoryCard checkIn={checkIn} />
                            {myCheckInList.length - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space16)' }} />}
                        </Fragment>)}

                    </div>
                </div>
            </>}
    </>
}

export { TrainingHistory }